/**
 * Created by harbon on 16/10/15.
 */
//@ts-check
import { api } from './base'
//@ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'

const _getOne = async function (postId) {
  try {
    const res = await api.get(`/posts/${postId}`)
    return res.data.result
  } catch (err) {
    if (err.response) {
      console.error(err.response.data)
    } else {
      console.error(err)
    }
    throw err
  }
}

export const release = async (post) => {
  try {
    const res = await api.post('/posts', post)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const removeArtwork = async function (postId) {
  try {
    const res = await api.delete(`/posts/${postId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getBuyers = async function (postId, page, limit = 10) {
  try {
    const res = await api.get(`/posts/${postId}/buyers`, {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUserPostsTill = async function (userId, timestamp, limit = 5) {
  try {
    const res = await api.get('/posts/old', {
      params: {
        userId,
        timestamp,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const promotePost = async function (postId) {
  try {
    const res = await api.post(`/posts/${postId}/promote`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const unpromotePost = async function (postId) {
  try {
    const res = await api.post(`/posts/${postId}/unpromote`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

const _getSocialImageUrl = async function (postId) {
  try {
    const res = await api.get(`/posts/${postId}/socialImage`)
    return res.data.result.url
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getSocialImageUrl = reusePendingPromise(_getSocialImageUrl)

export const updatePost = async function (postId, doc) {
  try {
    const res = await api.put(`/posts/${postId}`, {
      ...doc
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

const _getPostTranslation = async function (postId) {
  try {
    const res = await api.get(`/posts/${postId}/translations`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getPostTranslation = reusePendingPromise(_getPostTranslation)

export const searchSelfPosts = async function (keyword, limit, page) {
  try {
    const res = await api.get('/posts/search/self', {
      params: {
        keyword,
        limit,
        page
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const searchPosts = async function (keyword, limit, page) {
  try {
    const res = await api.get('/posts/search', {
      params: {
        keywords: keyword,
        limit,
        page
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUserPromotedPosts = async function (userId) {
  try {
    const res = await api.get('/posts/promote', {
      params: {
        userId
      }
    })
    const posts = res.data.result
    posts.forEach(post => post.pinningUserId = userId)
    return posts
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const warmupArtwork = async function (postId) {
  try {
    const res = await api.post(`/posts/${postId}/warmup`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  getOne: reusePendingPromise(_getOne),

  async getComicPostsNew (timestamp, limit, circleId, sort) {
    try {
      const res = await api.get(`/groups/${circleId}/postsNew`, {
        params: {
          timestamp,
          sort,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getComicPostsOld (timestamp, limit, circleId, sort) {
    try {
      const res = await api.get(`/groups/${circleId}/postsOld`, {
        params: {
          timestamp,
          sort,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async buy(postId, currency = 'JPY', {
    processor,
    channelId = null,
    returnUrl,
    subProcessor,
    discountCode,
  }) {
    try {
      const res = await api.post(`/posts/${postId}/buy`, {
        currency,
        channelId,
        processor,
        returnUrl,
        subProcessor,
        discountCode,
      })

      const data = res.data
      if (data.code === 200) {
        return data.result
      } else if (data.code === 117) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async like(postId) {
    try {
      const res = await api.post(`/posts/${postId}/like`)
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async discount(postId, discount) {
    try {
      const res = await api.post(`/posts/${postId}/discount`, { discount })
      return res.data.code === 200
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async setTitle(postId, title) {
    try {
      const res = await api.post(`/posts/${postId}/updateTitle`, { title })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getTimelinePostsSince(timestamp, limit) {
    try {
      const res = await api.get('/posts/followings/new', {
        params: {
          timestamp,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getTimelinePostsTill(timestamp, limit) {
    try {
      const res = await api.get('/posts/followings/old', {
        params: {
          timestamp,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getUserHotPosts(userId, page, limit) {
    try {
      const res = await api.get('/posts/heat', {
        params: {
          userId,
          page,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getUserPurchased(userId, page, limit) {
    try {
      const res = await api.get('/posts/paid', {
        params: {
          limit,
          page
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getVolitePosts(limit, page) {
    try {
      const res = await api.get('/posts/illegalPosts', {
        params: {
          limit,
          page
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async legalUpdate(legalTitle, legalContent, legalMedias, postId) {
    try {
      const res = await api.post(`/posts/${postId}/legalUpdate`, {
        legalTitle,
        legalContent,
        legalMedias
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getonelegal(postId) {
    try {
      const res = await api.get(`/posts/${postId}/illegalPost`)
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async jointRequestPosts(limit, page) {
    try {
      const res = await api.get('/posts/jointRequestPosts', {
        params: {
          limit,
          page
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async jointUpdate(postId, status) {
    try {
      const res = await api.post(`/posts/${postId}/jointUpdate`, {
        status
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
