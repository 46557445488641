<template>
  <div style="display: flex; flex-direction: column;">
    <div
      v-for="option in options"
      :id="option.value"
      :key="option.value"
      style="display: flex; margin: 0.5em 0; align-items: center; gap: 0.5rem;">
      <radio-button
        v-model="selection"
        :value="option.value">
      </radio-button>
      <label :for="option.value">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>


<script>
//@ts-check
import RadioButton from 'primevue/radiobutton'
import { ref, watch } from 'vue'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const selection = ref(props.modelValue)

    watch(selection, (newVal) => {
      emit('update:modelValue', newVal)
    })

    watch(() => props.modelValue, () => {
      selection.value = props.modelValue
    })

    return {
      selection
    }
  },
  components: {
    RadioButton
  }
}
</script>

<style lang="less" scoped>
</style>