export const zh = {
  follow: '关注',
  followed: '已关注',
  followers: '粉丝',
  following: '关注',
  works: '作品',
  getInTouch: '获取联系方式',
  latest: '最新',
  hot: '热门',
  free: '免费',
  searchbarPlaceholder: '搜索作品、用户与频道',
  users: '用户',
  channels: '频道',
  wallet: '钱包',
  logout: '退出登录',
  add: '添加',
  added: '已添加',
  report: '举报',
  coins: '金币',
  loadmore: '加载更多',
  loading: '加载中...',
  nomore: '已无更多',
  login: '登录',
  getCaptcha: '获取验证码',
  enterCaptcha: '请输入验证码',
  timeleft: '还剩 {time} 秒',
  loginToPR: '登录 Perohub',
  check: '看看有无新作品',
  charge: '充值',
  mycoins: '我的金币',
  purchased: '已购作品',
  mywallet: '我的钱包',
  updateProfile: '编辑个人资料',
  updatePhone: '修改或绑定手机',
  verification: '原创认证',
  postDetail: '作品详情',
  comment: '评论',
  moreOfHer: '查看 {username} 的更多作品',
  syncWithApp: '保存购买记录',
  back: '返回',
  success: '成功',
  fail: '失败',
  paymentResult: '支付结果',
  payment: '支付',
  banMessage: '{username}因为「{reason}」已被封号',
  products: '产品',
  uploader: '上传工具',
  downloadApp: '下载 App',
  about: '关于',
  rules: '社区规范',
  tos: '用户协议',
  links: '传送门',
  reportCenter: '举报中心',
  reply: '回复',
  send: '发送',
  repliesTotal: '共有 {total} 条回复，点击查看',
  commentOf: '{username}的评论',
  enterComment: '输入评论',
  pleaseLogin: '请先登录',
  nomoreComment: '暂无评论',
  discountQuote: '打折后 {price}',
  starWithCount: '收藏（{starCount}）',
  star: '收藏',
  payout: '提现',
  confirm: '确认',
  payoutApplied: '提现已申请',
  manga: '漫画',
  photo: '写真',
  joinedCircles: '我加入的应援团',
  circles: '所有应援团',
  viewAll: '查看全部',
  joinCircle: '加入 {title}',
  month: '月',
  week: '周',
  day: '日',
  circleFrequency: '每{period} {count} 更',
  preparing: '准备中',
  updating: '连载中',
  completed: '已完结',
  join: '加入',
  dm: '私信',
  overview: '目录',
  joined: '已加入',
  mypage: '我的主页',
  myworks: '我的作品',
  share: '分享',
  collectInto: '收录至频道',
  addToCircle: '添加至应援团',
  applyVerify: '申请原创认证',
  publish: '发布作品',
  notifications: '消息',
  modify: '更改',
  bind: '绑定',
  nickname: '昵称',
  inputNickname: '输入昵称',
  save: '保存',
  sendOK: '发送成功',
  viewMore: '查看更多',
  edit: '编辑',
  cancel: '取消',
  delete: '删除',
  recommendedSth: '推荐{item}',
  circle: '应援团',
  topic: '专题',
  allTopics: '全部专题',
  allRecommendedUsers: '所有推荐用户',
  latestPicks: '最新收录',
  channelOwner: '频道主',
  subscribe: '订阅',
  best: '精华',
  adds: '{count} 人添加',
  chooseStarItem: '选择收藏的项目',
  blacklist: '黑名单',
  settings: '设置',
  creator: '创作者',
  gender: '性别',
  male: '男',
  female: '女',
  secret: '保密',
  brief: '简介',
  setAutoreply: '付费联系方式',
  chatPrice: '解锁联系方式所需价格',
  resetChat: '不设置联系方式',
  buyers: '谁购买了',
  collect: '收录',
  createChannel: '创建频道',
  channelManagement: '频道管理',
  channelTitle: '频道名称',
  inputChannelTitle: '输入频道名称',
  needVerify: '投稿需要审核',
  setRoyaltyRate: '设置收入分成',
  channelDescription: '频道简介',
  pleaseInputChannelDescription: '请填写关于该频道的基本信息',
  submit: '提交',
  contactBuyers: '谁想联系我',
  openCircle: '开通应援团',
  circleTitle: '应援团名称',
  inputCircleTitle: '输入应援团名称',
  circleDescription: '宣传标语',
  circleIntroduction: '应援团介绍',
  inputCircleDescription: '输入宣传标语',
  inputCircleIntroduction: '输入应援团介绍',
  clickBelowToUploadCircleCover: '点击下方按钮上传应援团封面',
  uploadCover: '上传封面',
  recurringPrice: '连续包月价格',
  upfrontPrice: '完结后买断价格',
  keizaiTimeLength: '连载时长（连载到期后应援团只允许买断）',
  year: '一年',
  halfYear: '半年',
  quarter: '一个季度',
  month: '月',
  oneMonth: '一个月',
  forever: '长期',
  cannotComplete: '无法完结应援团',
  circleType: '应援团类型',
  shuffle: '换一批',
  promote: '推广',
  circleAdInPost: '{price}浏览作者当月所有作品',
  circleAdInPostFreeComplement: '每月 {price}，浏览作者{duration}作品',
  circleAdInPostUpfront: '{price}，浏览作者{duration}作品',
  artworkChannel: '该作品被收录到以下频道',
  contribute: '投稿',
  submittedBy: '投稿人',
  approve: '通过',
  reject: '拒绝',
  removeFromChannel: '从频道中移除',
  review: '审核投稿',
  artworkDetail: '作品详情',
  block: '拉黑',
  unblock: '解除拉黑',
  clearCache: '清除多媒体缓存',
  payToUnlock: '支付 {price} 解锁',
  willPublishR18: '我以后可能会发布成人内容',
  communityGuideline: '社区规约',
  topArtwork: '置顶作品',
  untopArtwork: '取消置顶',
  editMemberTag: '编辑应援团卡片',
  cancelNextMonth: '下月起退订',
  circleMembers: '应援团成员',
  completeCircle: '完结应援团',
  channelDetail: '频道详情',
  search: '搜索',
  noUsersFound: '没有找到相关用户',
  noChannelsFound: '没有找到相关频道',
  noPostsFound: '没有找到相关作品',
  viewBangou: '第 {no} 张',
  artworkTitle: '作品标题',
  artworkContent: '作品文字介绍',
  freeCover: '免费',
  artworkType: '作品类型',
  canBeCollected: '可被收录至频道',
  referencePlaceholder: '原文链接或者来源信息（必填）',
  referenceTip: '转载内容需要注明出处，且需取得必要授权',
  reference: '转载',
  original: '原创',
  pleaseChoose: '请选择',
  dontAddToCircle: '不关联',
  automaticEpisode: '自动话数',
  extraEpisode: '番外',
  autoDelete: '定时删除',
  autoDeleteString: '{timeString} 后删除 点击修改',
  clickToSelect: '点击选取',
  autoSetRest: '从 {currency} 自动换汇',
  setFree: '设为免费',
  overview: '概览',
  detail: '明细',
  whyRejectPayout: '为什么提现会被拒绝？',
  yesiam18: '是的，我已满 18 岁',
  no: '不',
  bindPayout: '绑定提现方式',
  copyLink: '复制链接',
  allReports: '全部举报',
  myReports: '我的举报',
  reportsToMe: '针对我的举报',
  privacyTerms: '隐私条款',
  disclosure: '《特定商业交易法》披露',
  submitToChannels: '投稿给频道',
  discount: '打折促销',
  collectRequest: '作品收录请求',
  coworkRequest: '联合发布请求',
  changeTitle: '修改标题',
  submitToTopics: '投稿到专题',
  shareChannel: '频道分享',
  buyGiftCode: '购买礼品卡',
  useGiftCode: '使用礼品卡',
  joinThisCircle: '加入该应援团：',
  payoutBindTip: '如实选择你的银行地址，虚假信息将会导致无法收到提现',
  notPurchasedYet: '还未购买',
  mangaDetail: '漫画详情',
  username: '用户名',
  inputUsername: '输入用户名',
  artworkCategories: '作品分类',
  nothingHere: '空空如也',
  categoryTitle: '分类名',
  addCategory: '新增分类',
  orders: '账单',
  totalAmount: '支付总额',
  giftcode: '礼品卡',
  contentInPast: '过去的内容',
  contentSinceNow: '今后的内容',
  tillSerializationOver: '直到连载完结',
  artworkOnDate: '{date} 作品',
  stillProcessing: '作品正在转码中，你可以关闭页面，稍后再来查看',
  verifyNotice: 'KYC(Know Your Customer) 是美国监管要求，认证由 Veriff 进行，Perohub 不存储您的个人信息。',
  verifyBonus: '<b>部分地区用户如果仍然担心个人安全，可以推特大号联系 <a class="outline" href="https://twitter.com/perohubcom" target="_blank">@perohubcom</a> 跳过认证</b>',
  notifySetting: '通知设置',
  authorFollowMe: '认证作者关注了我',
  authorNewPost: '关注的作者发布了新作品',
  commentNew: '评论提醒',
  authorSubmitMyChannel: '有作者投稿到了我的频道',
  channelNoPosts: '该频道暂未收录作品',
  searchChannels: '搜索你想要添加的频道',
  addedChannels: '已添加的频道',
  pastContentPerMonth: '往期内容单价',
  ifCustomerJoinsAtMonth: '如果支持者于第 {index} 个月加入',
  theCustomerNeedsToPay: '支持者需要支付',
  customerPricePreview: '支付价格预览',
  circleHasCompleted: '应援团已完结',
  illustration: '插画',
  outofservice: '内容在您的地区 {countryCode} 无法显示',
  selectChapters: '选择章节',
  nextChapter: '下一话',
  prevChapter: '上一话',
  alreadyFirstPage: '已经是第一页',
  useWatermark: '动态水印（显示浏览者 ID）',
  useAutoTranslation: '使用自动翻译',
  useManualTranslation: '编辑其他语言版本',
  aisatuTokenTitle: '你的好友口令是 {code}',
  aisatuUnlockText: '支付 {price} 解锁「{nickname}」的联系方式？',
  searchAisatuToken: '搜索好友口令',
  givenName: '名',
  lastName: '姓',
  forceComplement: '入团时强制补票往期所有月份',
  complementPrice: '每月补票价格',
  whenUserJoins: '当新成员加入时',
  circlePriceText: '该成员当月，以及之后的每个月都需要支付 {priceText}',
  circleComplementPriceText: '要加入应援团，该成员必须解锁此前的所有作品，价格 {priceText} / 月',
  circleZeroComplementPriceText: '该成员可免费解锁此前的所有作品',
  circleNonForceComplementPriceText: '该成员可以稍后自主选择是否解锁此前的作品，价格 {priceText} / 月',
  circleUpfrontPriceText: '如果此时 {title} 完结，该成员必须支付 {priceText} 解锁所有内容',
  selectFreeCoverTip: '点击图片开关免费封面',
  zh: '汉语',
  en: 'English',
  ja: '日本語',
  ko: '한국인',
  requiresCircleRight: '应援团限定',
  onlyForMembers: '应援团限定',
  siteLang: '网站语言',
  complement: '往期补票',
  fromChannel: '来自 {owner} 的频道「{title}」',
  enablePushNotification: '推送通知',
  notifcationDeniedWarning: '由于 Perohub 的浏览器通知权限被拒绝，无法接收到推送通知',
  oneArtistMaxPresentsInLatest: '时间线中每位作者最多展示',
  limitArtistDisplay: '限制每位作者在时间线中展示的作品数',
  allChapters: '全部章节（{count}）',
  reversedOrder: '倒序',
  normalOrder: '顺序',
  article: '文章',
  addTextBlock: '添加付费解锁文字块',
  unfree: '付费',
  createOnPerohub: '在 Perohub 上创作',
  promoteLineOne: '艺术家们，这是获取被动收入的最佳平台！',
  globalPaymentsSupport: '支持全球支付',
  displayOnWebsite: '直接在网站上展示内容，无需额外发货',
  getPaidMonthly: '按月获得订阅制支持',
  sellSingleItems: '出售数字单品',
  getPaidForPast: '订阅制可以要求补票往期内容',
  alipaySupport: '支持微信支付宝',
  watermarkSupport: '防盗图水印',
  realtimePayout: '实时提现，无人工审批',
  usdtPayout: 'USDT 提现，安全匿名',
  directSupport: '来自网站开发者本人的客户支持',
  bankTransfer: 'Bank Transfer',
  threedasset: '3D Asset',
  canDownload: '允许下载',
  downloads: '下载',
  downloadCreated: 'Zip 下载任务已创建',
  goToDownloads: '前往任务页面',
  refresh: '刷新',
  unlockCirclePrompt: '加入 {title}，解锁更多相关作品？还是只解锁当前作品？',
  unlockCirclePromptAccept: '加入应援团',
  unlockCirclePromptReject: '只解锁当前作品',
  visitorMode: '其他人看我',
  inVisitorMode: '以访客身份浏览中',
  visitorModeExplain: '显示了低像素图，真正的模糊图会在真实模式下显示给访客',
  paymentComplete: '支付完成后点我',
  zipTaskAutoDeleteTip: '下载任务会在一段时间后自动删除',
  r18tip: '此页面可能包含成人内容，请确保你已年满 18 岁，否则请立即离开',
  forYou: '随便看看',
  useAlgoTimeline: '显示推荐',
  removeFromCircle: '移出应援团',
  grantCircleRight: '赠送应援团权限',
  grantUser: '赋权用户',
  grantAccessTo: '赠送 {title} 的权限',
  hideStars: '隐藏收藏数',
  showStars: '显示收藏数',
  alipay: '支付宝',
  wechatPay: '微信支付',
  tryRefreshPage: '请尝试刷新网页',
  addMedia: '添加媒体',
  loginAutoCreate: '首次登录将自动创建账号',
  chooseDeletion: '请选择您要删除的内容',
  unstarOK: '取消收藏成功',
  assignment: '委托',
  createAssignment: '创建委托',
  assignmentUnavailable: '作者不接受委托',
  acceptAssignment: '接受委托',
  assignmentTemplate: '委托提交模板',
  minAssignmentPrice: '委托定金',
  assignmentPublic: '公开委托',
  assignmentPublicDesc: '其他用户也可以按照作者设定的价格购买最终成品',
  assignmentNotPublicDesc: '只有你可以获取最终成品',
  assignmentPrice: '委托价格',
  currency: '货币',
  sendAssignment: '发送委托',
  deliverCommission: '交付委托',
  ['waiting-for-payment']: '等待支付',
  ['waiting-for-resolve']: '等待验收',
  open: '接单中',
  working: '制作中',
  reviewing: '验收中',
  assignerRejected: '验收未通过',
  assigneeRejected: '拒绝接单',
  resolved: '已结单',
  closed: '放弃委托',
  issueOpened: '委托人已支付',
  issueAssignerRejected: '委托人拒绝验收',
  issueWorking: '开始制作',
  issueSubmitted: '作者提交验收',
  issueAssigneeRejected: '作者拒绝接单',
  issueResolved: '委托完成',
  issueClosed: '作者放弃委托',
  issueWaitingForResolve: '等待委托人验收',
  issueCommentPlaceholder: '仅在你和 {nickname} 之间可见',
  email: '邮箱',
  password: '密码',
  register: '注册',
  bindPhoneNumberPrompt: '根据作者的水印设置，为了保护版权，请在 Perohub 绑定手机号以继续购买',
  sending: '发送中...',
  updatePassword: '修改密码',
  updateEmail: '修改邮箱',
  unlimited: '无制限',
  never: '从不',
  create: '创建',
  discountCodes: '折扣码',
  useDiscountCode: '使用折扣码（可选）',
  enterDiscountCode: '输入折扣码',
  checkout: '购买',
  issuePriceNotEnough: '价格必须大于作者设置的定金 {currency} {price}',
  acceptCommission: '接受委托',
  rejectCommission: '拒绝委托',
  continueCommission: '继续委托',
  giveUpCommission: '放弃委托',
  makePublicSale: '公开贩售',
  pickCocreators: '选择合作者',
  setCocreators: '设置联合创作',
  searchByNickname: '按昵称搜索',
  wantsToCollaborate: '想和你一起发布作品',
  youCanGetRevenue: '你可以获得 {shareRate}% 的收益',
  revenueShares: '收益分成',
  andOthers: '{nickname} 等人',
  watermarkPreparing: '该文件正在准备中',
  needsWarmup: '根据{nickname}的要求，该作品需要额外的水印转码',
  initiateWarmup: '开始转码',
  underMantenance: '维护中',
  retry: '重试',
  uploading: '上传中',
  acceptAnonymousAsk: '接受匿名提问',
  askPrice: '提问价格',
  anonymous: '匿名',
  questions: '提问',
  askAnonymousQuestion: '匿名提问',
  peekPrice: '其他人查看回答的价格',
  payToAsk: '支付 {price} 提问',
  questionPlaceholder: '匿名提问',
  peekDescription: '你和{nickname}平分其他人查看回答的收益',
  pendingAnswer: '等待 {nickname} 回答',
  askIsNoRefund: '即使 {nickname} 不回答，你也不会获得退款',
  answer: '回答',
  enableVideoDefaultWatermark: '启用视频默认水印',
}
