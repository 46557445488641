//@ts-check
import { computed, onMounted, ref, watch, isRef } from "vue"
import { useI18n } from 'vue-i18n'
import { APP_URL } from "@/config"

import { useCircleStore } from "@/pinia/circle"
import { useCurrentUser } from "@/composables/user_data"
import { removeArtwork, promotePost, unpromotePost } from '@/api/post'
import { removeChannelPost } from "@/api/channel"
import { useRoute, useRouter } from "vue-router"
import { useApplication } from "@/pinia/application"
import { useChannelStore } from "@/pinia/channel"
import { eventEmitter } from '@/utils'
import { pinChannelPost, unpinchannelPost } from '@/api/channel'
import { events } from "@/utils/events"
import { useToast } from "./toast"
import { removePostFromCircle } from "@/api/circle"


export const usePostMoreMenu = function (post, plainChannelId = null) {
  const { t } = useI18n()
  const reportShow = ref(false)
  const circleStore = useCircleStore()
  const { currentUser, authenticated, currentUserId } = useCurrentUser()
  const router = useRouter()
  const { openChannelCollector, openCircleCollector, openSocialSharing } = useApplication()
  const channelStore = useChannelStore()
  const route = useRoute()
  const moreMenuVisible = ref(false)
  const { Toast } = useToast()
  let channelId = plainChannelId
  if (route.params.channelId) {
    channelId = route.params.channelId
  }
  if (channelStore.sourceChannelId) {
    channelId = channelStore.sourceChannelId
  }
  const circleId = route.params.circleId

  const menuItems = computed(() => {
    const items = []

    let _post = post

    if (isRef(post)) {
      _post = post.value
    }

    if (!_post) {
      return items
    }

    items.push({
      label: t('share'),
      async action () {
        try {
          moreMenuVisible.value = false
          const url = new URL(`${APP_URL}/posts/${_post._id}`)
          if (channelStore.sourceChannel) {
            url.searchParams.append('cid', channelStore.sourceChannel.uniqueNum)
          }
          openSocialSharing(_post.title, url)
        } catch (err) {
          console.error(err)
          Toast({
            message: err.message,
          })
        }
      },
      isDanger: false,
    })

    items.push({
      label: t('copyLink'),
      async action () {
        try {
          const url = new URL(`${APP_URL}/posts/${_post._id}`)
          if (channelStore.sourceChannel) {
            url.searchParams.append('cid', channelStore.sourceChannel.uniqueNum)
          }
          await navigator.clipboard.writeText(url.toString())
          Toast({ message: 'link copied' }, 'info')
        } catch (err) {
          console.error(err)
          Toast({
            message: err.message,
          })
        } finally {
          
        }
      },
      isDanger: false,
    })

    items.push({
      label: t('report'),
      action () {
        moreMenuVisible.value = false
        reportShow.value = true
      },
      isDanger: false,
    })

    if (channelStore.manageableChannels.length > 0) {
      items.push({
        label: t('collectInto'),
        action () {
          moreMenuVisible.value = false
          openChannelCollector(_post._id)
        },
        isDanger: false,
      })

      let collectedInCurrentChannel = false

      for (const channel of _post.channels.values()) {
        if (channel._id === channelId) {
          collectedInCurrentChannel = true
          break
        }
      }

      if (collectedInCurrentChannel) {
        items.push({
          label: t('topArtwork'),
          async action () {
            try {
              moreMenuVisible.value = false
              await pinChannelPost(channelId, _post._id)
              Toast({ message: t('success') }, 'ok')
            } catch (err) {
              Toast({
                message: err.message,
              })
            }
          }
        })

        items.push({
          label: t('untopArtwork'),
          async action () {
            try {
              moreMenuVisible.value = false
              await unpinchannelPost(channelId, _post._id)
              Toast(t('success'), 'ok')
            } catch (err) {
              Toast({
                message: err.message,
              })
            }
          }
        })
      }
    }

    if (circleStore.manageableCircles.length > 0 && _post.user._id === currentUser.value._id) {
      items.push({
        label: t('addToCircle'),
        action () {
          moreMenuVisible.value = false
          openCircleCollector(_post._id)
        },
        isDanger: false,
      })
    }


    if (channelId) {
      if (channelStore.manageableChannels.map(channel => channel._id).includes(channelId)) {
        items.push({
          label: t('removeFromChannel'),
          async action () {
            try {
              await removeChannelPost(channelId, _post._id)
              eventEmitter.emit(events.ArtworkRemovedFromChannel, _post._id, channelId)
              moreMenuVisible.value = false
              Toast(t('success'), 'ok')
            } catch (err) {
              Toast({
                message: err.message,
                position: 'bottom',
              })
            }
          },
          isDanger: true,
        })
      }
    }


    if (circleId) {
      items.push({
        label: t('removeFromCircle'),
        async action () {
          try {
            await removePostFromCircle(_post._id, circleId)
            Toast(t('success'), 'ok')
          } catch (err) {
            Toast(err.message)
          }
        }
      })
    }


    if (_post.user._id === currentUserId.value) {
      if (_post.pinningUserId === currentUserId.value) {
        items.push({
          label: t('untopArtwork'),
          async action () {
            try {
              await unpromotePost(_post._id)
              Toast(t('success'), 'ok')
            } catch (err) {
              Toast({
                message: err.message
              })
            }
          }
        })
      } else {
        items.push({
          label: t('topArtwork'),
          async action () {
            try {
              await promotePost(_post._id)
              Toast(t('success'), 'ok')
            } catch (err) {
              Toast({
                message: err.message
              })
            }
          }
        })
      }

      if (_post.price !== 0) {
        items.push({
          label: t('buyers'),
          isDanger: false,
          async action () {
            router.push(`/posts/${_post._id}/buyers`)
          }
        })
      }

      items.push({
        label: t('edit'),
        isDanger: false,
        action () {
          router.push(`/posts/${_post._id}/edit`)
        }
      })

      items.push({
        label: t('visitorMode'),
        isDanger: false,
        action () {
          router.push(`/posts/${_post._id}?visitor=1`)
        }
      })

      items.push({
        label: t('delete'),
        isDanger: true,
        async action () {
          try {
            
            await removeArtwork(_post._id)
            eventEmitter.emit(events.ArtworkDeleted, _post._id)
            moreMenuVisible.value = false
            Toast(t('success'), 'ok')
          } catch (err) {
            Toast({
              message: err.message,
            })
          } finally {
            
          }
        }
      })
    }

    return items
  })

  watch(authenticated, () => {
    if (authenticated.value) {
      init()
    }
  })

  const init = async function () {
    const promises = []
    if (channelStore.manageableChannels.length === 0) {
      promises.push(channelStore.fetchManageableChannels())
    }

    if (circleStore.manageableCircles.length === 0) {
      promises.push(circleStore.fetchManageableCircles(currentUser.value._id))
    }

    await Promise.all(promises)
  }


  onMounted(async () => {
    if (authenticated.value) {
      await init()
    }
  })


  return {
    reportShow,
    menuItems,
    moreMenuVisible,
  }
}