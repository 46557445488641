//@ts-check
import { getWarmupStatus } from '@/api/warmup'
import { onMounted, onUnmounted, ref } from 'vue'
import { useToast } from './toast'
import { until } from '@vueuse/core'
import { useCurrentUser } from './user_data'
import { computed } from 'vue'
import { warmupArtwork } from '@/api/post'

export const useArtworkWarmup = ({ artworkProp }) => {
  const { Toast } = useToast()
  const { currentUserId } = useCurrentUser()
  const initiating = ref(false)
  const tasks = ref({
    ongoingTasks: [],
    pendingTasks: [],
    doneTasks: [],
  })
  const warmupStatusInited = ref(false)
  const checking = ref(false)
  let intervalId = null

  const artwork = computed(() => artworkProp)

  const watermarkEnabled = computed(() => {
    if (!artwork.value) {
      return false
    }

    if (!artwork.value.watermark) {
      return false
    }

    return artwork.value.watermark.enabled && artwork.value.watermark.positions.length > 0
  })

  const needsWarmup = computed(() => {
    if (!watermarkEnabled.value) {
      return false
    }

    if (!artwork.value.paid) {
      return false
    }

    return true
  })

  const imageStatusMap = computed(() => {
    const map = {}
    // watermarked_image_userid_mediaid
    for (const taskKey of tasks.value.doneTasks.values()) {
      if (taskKey.includes('watermarked_image')) {
        const [_, __, ___, mediaId] = taskKey.split('_')
        map[mediaId] = true
      }
    }

    for (const taskKey of [...tasks.value.ongoingTasks, ...tasks.value.pendingTasks].values()) {
      if (taskKey.includes('watermarked_image')) {
        const [_, __, ___, mediaId] = taskKey.split('_')
        if (!map[mediaId]) {
          map[mediaId] = false
        }
      }
    }

    return map
  })

  const videoResolutionStatusMap = computed(() => {
    const map = {}

    // chunks-mediaid-resolution-viewerid-filename
    for (const taskKey of tasks.value.doneTasks.values()) {
      const [_, mediaId, resolution, __, ___] = taskKey.split('-')
      if (!map[mediaId]) {
        map[mediaId] = []
      }
      map[mediaId].push(resolution)
    }

    for (const taskKey of [...tasks.value.ongoingTasks, ...tasks.value.pendingTasks].values()) {
      const [_, mediaId, resolution, __, ___] = taskKey.split('-')
      if (!map[mediaId]) {
        map[mediaId] = []
      }

      if (map[mediaId].includes(resolution)) {
        map[mediaId].splice(map[mediaId].indexOf(resolution), 1)
      }
    }

    return map
  })

  const browsable = computed(() => {
    if (warmupStatus.value === 'no_need') {
      return true
    }

    if (warmupStatus.value === 'done') {
      return true
    }

    if (warmupStatus.value === 'processing') {
      return true
    }

    if (warmupStatus.value === 'failed') {
      return true
    }

    return false
  })

  const warmupStatus = computed(() => {
    if (!needsWarmup.value) {
      return 'no_need'
    }

    if (tasks.value.ongoingTasks.length === 0 && tasks.value.pendingTasks.length === 0 && tasks.value.doneTasks.length === 0) {
      return 'not_inited'
    }

    if (tasks.value.doneTasks.length > 0 && tasks.value.ongoingTasks.length === 0 && tasks.value.pendingTasks.length === 0) {
      return 'done'
    }

    if (tasks.value.ongoingTasks.length === 0 && tasks.value.doneTasks.length === 0) {
      return 'not_started'
    }

    if (tasks.value.pendingTasks.length > 0) {
      return 'failed'
    }

    return 'processing'
  })

  const tasksTotal = computed(() => {
    return tasks.value.ongoingTasks.length + tasks.value.pendingTasks.length + tasks.value.doneTasks.length
  })

  const initiateArtworkWarmup = async function () {
    try {
      initiating.value = true
      await warmupArtwork(artwork.value._id)
      Toast('preparing started', 'ok')
      await getObservingTasks()
      while (warmupStatus.value === 'not_started') {
        await new Promise(resolve => setTimeout(resolve, 5000))
        await getObservingTasks()
      }
      await startObservingTasks()
    } catch (err) {
      console.error(err)
      Toast(err)
    } finally {
      initiating.value = false
    }
  }

  const startObservingTasks = async function () {
    await getObservingTasks()

    if (warmupStatus.value !== 'processing') {
      return
    }

    await new Promise(resolve => setTimeout(resolve, 5000))
    return await startObservingTasks()
  }

  const getObservingTasks = async function () {
    try {
      await until(currentUserId).toMatch(userId => userId !== null)
      const { ongoingTasks, pendingTasks, doneTasks } = await getWarmupStatus(artwork.value._id, currentUserId.value)
      tasks.value = {
        ongoingTasks,
        pendingTasks,
        doneTasks,
      }
    } catch (err) {
      console.error(err)
      Toast(err)
    } finally {
      warmupStatusInited.value = true
    }
  }

  const checkAll = async function () {
    try {
      await until(artwork).toMatch(artworkValue => {
        if (artworkValue === null) {
          return false
        }
        return artworkValue.paid
      })

      checking.value = true
      await getObservingTasks()
    } catch (err) {
      console.error(err)
      Toast(err)
    } finally {
      checking.value = false
    }
  }

  onMounted(async () => {
    if (!needsWarmup.value) {
      return
    }
    await getObservingTasks()
  })

  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  })

  return {
    watermarkEnabled,
    needsWarmup,
    initiateArtworkWarmup,
    initiating,
    warmupStatus,
    tasks,
    tasksTotal,
    checkAll,
    checking,
    imageStatusMap,
    videoResolutionStatusMap,
    warmupStatusInited,
    browsable,
  }
}
