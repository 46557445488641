<template>
  <drawer
    v-if="isOnMobile"
    v-model:visible="visible"
    :position="fullscreenOnMobile ? 'full' : 'bottom'"
    class="popup-sidebar"
    @hide="emit('hide')">
    <template #header>
      <slot name="header"></slot>
    </template>
    <slot></slot>
    <slot name="footer"></slot>
  </drawer>
  <Dialog
    v-else
    v-model:visible="visible"
    :modal="true"
    @hide="emit('hide')">
    <template #header>
      <slot name="header">&nbsp;</slot>
    </template>
    <slot></slot>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </Dialog>
</template>


<script>
//@ts-check
import { useResponsive } from '@/composables/responsive'
import Dialog from 'primevue/dialog'
import { ref, watch } from 'vue'
import Drawer from 'primevue/drawer'


export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    fullscreenOnMobile: {
      type: Boolean,
      default: false,
    }
  },
  setup (props, { emit }) {
    const visible = ref(props.modelValue)

    watch(visible, () => {
      emit('update:modelValue', visible.value)
    })

    watch(props, () => {
      visible.value = props.modelValue
    })

    return {
      visible,
      emit,
      ...useResponsive(),
    }
  },
  components: {
    Drawer,
    Dialog,
  }
}
</script>

<style>
.popup-sidebar .p-drawer-header-content {
  margin-right: auto;
}

.popup-sidebar .p-drawer-content {
  flex: 1;
}
</style>