//@ts-check
import { mediaApi } from './base'


export const checkImageWarmupStatus = async function ({
  userId,
  mediaId,
}) {
  try {
    const res = await mediaApi.head('/image_watermark_tasks', {
      params: {
        user_id: userId,
        media_id: mediaId,
      }
    })
    return res.status === 200
  } catch (err) {
    console.error(err)
    if (err.response) {
      return false
    }
    throw err
  }
}

export const checkVideoWarmupStatus = async function ({
  userId,
  resolution,
  filename,
  mediaId,
}) {
  try {
    const res = await mediaApi.head('/video_watermark_tasks', {
      params: {
        user_id: userId,
        resolution,
        filename,
        media_id: mediaId,
      }
    })
    return res.status === 200
  } catch (err) {
    console.error(err)
    if (err.response) {
      return false
    }
    throw err
  }
}

export const getWarmupStatus = async function (artworkId, userId) {
  try {
    const res = await mediaApi.get('/warmup', {
      params: {
        artwork_id: artworkId,
        user_id: userId,
      }
    })
    const { done_tasks, pending_tasks, ongoing_tasks } = res.data
    return {
      doneTasks: done_tasks ?? [],
      pendingTasks: pending_tasks ?? [],
      ongoingTasks: ongoing_tasks ?? [],
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}