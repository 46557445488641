<template>
  <div class="ask-container">
    <ask-cell
      v-if="ask"
      :ask="ask"
    ></ask-cell>

    <hr>

    <div v-if="ask">
      <div v-if="ask.status === 'pending-payment'">
        <div v-if="ask.userId === currentUserId && currentUserId !== null">
          <pr-button
            type="primary"
            style="width: 100%;"
            @click="pay">{{ $t('payment') }}</pr-button>
        </div>
      </div>
      <div v-else-if="ask.status === 'pending-answer'">
        <div
          v-if="ask.artistId === currentUserId"
          style="display: flex; flex-direction: column; gap: 1rem;">
          <Textarea
            v-model="answer"
            style="width: 100%; margin-top: 2rem;">
          </Textarea>

          <div v-if="ask.peekPrices['USD'] > 0">
            <p>Once answered, other users must pay {{ peekPriceText }} to see the answer</p>
            <p>You and the asker can take 50% of it</p>
          </div>
          <div v-else>
            <p>Once answered, all users can see the answer</p>
          </div>

          <pr-button
            :loading="answering"
            @click="answerQuestion">
            {{ $t('answer') }}
          </pr-button>
        </div>
        <p v-else>{{ $t('pendingAnswer', { nickname: ask.artist.nickname }) }}</p>
      </div>
      <div
        v-else-if="ask.status === 'answered'"
        class="answer-container">
        <div v-if="ask.paid">
          <p>{{ ask.answer }}</p>
        </div>
        <div
          v-else
          class="unpaid-container">
          <div class="unpaid-mask">
            <p>You need to pay {{ peekPriceText }} to see the answer</p>
          </div>
          <p>{{ ask.answer }}</p>
          <pr-button
            type="primary"
            size="small"
            style="width: 100%;"
            @click="pay">{{ $t('payment') }}</pr-button>
        </div>
        <div style="display: flex; justify-content: flex-end; width: 100%;">
          <user-avatar
            :user="ask.artist"
            style="width: 2rem; height: 2rem;"
            @click="$router.push(`/users/${ask.artist._id}`)">
          </user-avatar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//@ts-check
import AskCell from '@/components/AskCell.vue'
import { onMounted, ref } from 'vue'
import { useToast } from '@/composables/toast'
import { getAsk, updateAsk } from '@/api/ask'
import { useRoute } from 'vue-router'
import PrButton from '@/components/PRButton.vue'
import { useApplication } from '@/pinia/application'
import { useCurrentUser } from '@/composables/user_data'
import Textarea from 'primevue/textarea'
import { useCurrentCurrency } from '@/composables/currency'
import { computed } from 'vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { useHead } from '@unhead/vue'
import { whenever } from '@vueuse/core'
import { APP_URL } from '@/config'

export default {
  setup () {
    const ask = ref(null)
    const loading = ref(false)
    const askId = useRoute().params.askId
    const { showToast } = useToast()
    const application = useApplication()
    const answer = ref('')
    const answering = ref(false)
    const { currency } = useCurrentCurrency()

    const metaInfo = computed(() => {
      if (!ask.value) {
        return null
      }

      return {
        title: `${ask.value.question} | Perohub`,
        meta: [{
          name: 'keywords',
          content: `${ask.value.artist.nickname},${ask.value.artist.nickname},${ask.value.artist.nickname}`,
        }, {
          name: 'og:url',
          content: `${APP_URL}/asks/${ask.value._id}`,
        }, {
          name: 'og:title',
          content: `${ask.value.question} | Perohub`,
        }, {
          name: 'og:description',
          content: ask.value.question,
        }, {
          name: 'og:image',
          content: ask.value.artist.avatarURL,
        }, {
          name: 'twitter:image',
          content: ask.value.artist.avatarURL,
        }, {
          name: 'twitter:site',
          content: '@perohubcom',
        }, {
          name: 'twitter:title',
          content: `${ask.value.question} | Perohub`,
        }, {
          name: 'twitter:description',
          content: ask.value.question,
        }, {
          name: 'twitter:card',
          content: 'summary',
        }],
      }
    })

    const peekPriceText = computed(() => {
      if (currency.value === 'JPY') {
        return `JPY ${ask.value.peekPrices[currency.value]}`
      }

      return `${currency.value} ${(ask.value.peekPrices[currency.value] / 100).toFixed(2)}`
    })

    whenever(metaInfo, () => {
      useHead(metaInfo)
    }, {
      immediate: true,
    })

    const get = async function () {
      try {
        loading.value = true
        ask.value = await getAsk(askId)
      } catch (err) {
        showToast(err)
      } finally {
        loading.value = false
      }
    }

    const answerQuestion = async function () {
      try {
        answering.value = true
        await updateAsk(askId, {
          answer: answer.value,
        })
        await get()
      } catch (err) {
        console.error(err)
        showToast(err)
      } finally {
        answering.value = false
      }
    }

    const pay = function () {
      application.openPaymentPicker({
        askId: askId,
        processors: ['order2faka', 'alipay', 'wechat'],
      })
    }

    onMounted(() => {
      get()
    })

    return {
      ask,
      pay,
      ...useCurrentUser(),
      answer,
      answerQuestion,
      answering,
      currency,
      peekPriceText,
    }
  },
  components: {
    AskCell,
    PrButton,
    Textarea,
    UserAvatar,
  }
}
</script>


<style scoped lang="less">
@import '@/assets/base.less';

.ask-container {
  padding-top: 2rem;
  width: 30rem;
  margin-left: auto;
  margin-right: auto;

  @media @phone {
    padding: 1rem;
    width: 100%;
  }
}

.unpaid-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.unpaid-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(1px);
}

.answer-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

p {
  white-space: pre-wrap;
}
</style>
