<template>
  <div class="ask-cell">
    <div class="question">{{ ask.question }}</div>
    <div class="asker">
      <user-avatar
        v-if="ask.asker"
        :disabled="true"
        :user="ask.asker"
        class="avatar"
        @click="$router.push(`/users/${ask.asker._id}`)">
      </user-avatar>
      <div v-else>
        {{ $t('anonymous').toString() }}
      </div>
    </div>
  </div>
</template>


<script>
//@ts-check
import UserAvatar from "./UserAvatar.vue"

export default {
  props: {
    ask: {
      type: Object,
      required: true
    }
  },
  components: {
    UserAvatar
  }
}
</script>


<style scoped>
.ask-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.question {
  width: 100%;
}

.asker {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.avatar {
  width: 2rem;
  height: 2rem;
}
</style>
