<template>
  <div>
    <ask-list :asks="asks"></ask-list>

    <load-more-button
      v-if="askHasMore"
      :busy="askLoading"
      :has-more="askHasMore"
      @click="get">
    </load-more-button>
  </div>
</template>


<script>
//@ts-check
import AskList from '@/components/AskList.vue'
import { useAssigneeAsks } from '@/composables/ask_assignee'
import { useRoute } from 'vue-router'
import LoadMoreButton from '@/components/LoadMoreButton.vue'

export default {
  setup() {
    const userId = useRoute().params.userId
    const { asks, askPage, askHasMore, askLoading, get } = useAssigneeAsks(userId)

    return {
      asks,
      askPage,
      askHasMore,
      askLoading,
      get
    }
  },
  components: {
    AskList,
    LoadMoreButton
  }
}
</script>