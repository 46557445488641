import { api } from './base'


export const getMessages = async function (page = 1, limit = 10) {
  try {
    const res = await api.get('/messages', {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getMessage = async function (messageId) {
  try {
    const res = await api.get(`/messages/${messageId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateStatus = async function (messageId, status) {
  try {
    const res = await api.post(`/messages/${messageId}/updateStatus`, {
      status
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUnreadCount = async function () {
  try {
    const res = await api.get('/messages/unreadCount')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const clearAllMessages = async function () {
  try {
    const res = await api.post('/messages/clearAll')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}
