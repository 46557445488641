<template>
  <div class="asks-container">
    <ask-cell
      v-for="ask in asks"
      :key="ask._id"
      :ask="ask"
      class="touchable hoverable"
      @click="$router.push(`/asks/${ask._id}`)">
    </ask-cell>
  </div>
</template>

<script>
//@ts-check
import AskCell from './AskCell.vue'

export default {
  props: {
    asks: {
      type: Array,
      required: true
    }
  },
  components: {
    AskCell
  }
}
</script>


<style scoped>
.asks-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
</style>