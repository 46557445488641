//@ts-check
import { useToast } from "./toast"
import { useI18n } from 'vue-i18n'
import { useOneSignal as useOneSignalPlugin } from '@onesignal/onesignal-vue3'

export const useOneSignal = function () {
  const { Toast } = useToast()
  const { t } = useI18n()
  const onesignal = useOneSignalPlugin()

  const optIn = async function () {
    if (!('Notification' in window)) {
      return
    }

    try {
      if (!onesignal.Notifications.permission) {
        if (Notification.permission === 'denied') {
          Toast(t('notifcationDeniedWarning'), 'info')
        } else {
          await onesignal.Notifications.requestPermission()
        }
      }
      return await onesignal.User.PushSubscription.optIn()
    } catch (err) {
      console.error(err)
      Toast(err.message)
    }
  }

  const optOut = async function () {
    try {
      return await onesignal.User.PushSubscription.optOut()
    } catch (err) {
      console.error(err)
      Toast(err.message)
    }
  }

  const attachExternalId = async function (userId, nickname) {
    try {
      onesignal.User.addAlias('nickname', nickname)
      return await onesignal.login(userId)
    } catch (err) {
      console.error(err)
      Toast(err.message)
    }
  }

  const isOptedIn = async function () {
    try {
      return onesignal.User.PushSubscription.optedIn && onesignal.Notifications.permission
    } catch (err) {
      console.error(err)
      Toast(err.message)
      return false
    }
  }

  return {
    optIn,
    optOut,
    attachExternalId,
    isOptedIn,
  }
}