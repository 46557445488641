//@ts-check
import { ref } from 'vue'
import { useCurrentCurrency } from './currency'
import { useToast } from './toast'
import { createAsk } from '@/api/ask'
import { useRoute, useRouter } from 'vue-router'
import { useApplication } from '@/pinia/application'
import { computed } from 'vue'

export const useAskCreate = ({ targetUserRef }) => {
  const question = ref('')
  const { currency } = useCurrentCurrency()
  const anonymous = ref(false)
  const userId = useRoute().params.userId
  const peekPrices = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      USD: 0,
      JPY: 0,
      EUR: 0,
    }
  })
  const asking = ref(false)
  const { showToast } = useToast()
  const router = useRouter()
  const application = useApplication()

  const askPriceText = computed(() => {
    if (currency.value === 'JPY') {
      return `${currency.value} ${targetUserRef.value.ask.prices[currency.value]}`
    }
    return `${currency.value} ${(targetUserRef.value.ask.prices[currency.value] / 100).toFixed(2)}`
  })

  const ask = async function () {
    try {
      asking.value = true

      const savedAsk = await createAsk({
        question: question.value,
        anonymous: anonymous.value,
        artistId: userId,
        peekPrices: peekPrices.value.prices,
      })

      router.push(`/asks/${savedAsk._id}`)

      if (savedAsk.status === 'pending-payment') {
        application.openPaymentPicker({
          askId: savedAsk._id,
          processors: ['order2faka', 'alipay', 'wechat'],
        })
      }
    } catch (err) {
      console.error(err)
      showToast(err)
    } finally {
      asking.value = false
    }
  }

  return {
    question,
    anonymous,
    peekPrices,
    ask,
    createAsk,
    asking,
    askPriceText,
  }
}