//@ts-check
import { ref } from "vue"
import { useToast } from "@/composables/toast"
import { getMessages, clearAllMessages, getUnreadCount, updateStatus } from '@/api/message'
import { defineStore } from "pinia"
import { useCurrentUser } from "@/composables/user_data"

const limit = 10

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref([])
  const { Toast } = useToast()
  const unreadCount = ref(0)
  const { authenticated } = useCurrentUser()
  const clearing = ref(false)
  const page = ref(1)
  const loading = ref(false)
  const hasMore = ref(true)

  const fetch = async function () {
    try {
      loading.value = true
      hasMore.value = true
      page.value = 1
      notifications.value = await getMessages()
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const fetchMore = async function () {
    try {
      loading.value = true
      const result = await getMessages(page.value, limit)
      notifications.value.push(...result)
      page.value += 1
      hasMore.value = result.length === limit
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const fetchUnreadCount = async function () {
    try {
      if (authenticated.value) {
        unreadCount.value = await getUnreadCount()
      } else {
        unreadCount.value = 0
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const clearAll = async function () {
    try {
      clearing.value = true
      await clearAllMessages()
      await fetchUnreadCount()
      notifications.value = []
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      clearing.value = false
    }
  }

  
  const markAsRead = async function (notificationId) {
    try {
      await Promise.all([
        updateStatus(notificationId, 'read'),
        fetchUnreadCount(),
        fetch(),
      ])
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  return {
    unreadCount,
    fetch,
    clearAll,
    markAsRead,
    notifications,
    fetchUnreadCount,
    clearing,
    fetchMore,
    loading,
    hasMore,
    page,
  }
})
