//@ts-check
import { getUserAsks } from "@/api/ask"
import { onMounted, ref } from "vue"
import { useToast } from "./toast"
import { isRef } from "vue"
import { until } from "@vueuse/core"

const limit = 10

export const useAssigneeAsks = (userIdRefOrValue) => {
  const asks = ref([])
  const askPage = ref(1)
  const askHasMore = ref(true)
  const askLoading = ref(false)
  const { showToast } = useToast()

  const userId = isRef(userIdRefOrValue) ? userIdRefOrValue : ref(userIdRefOrValue)

  const getAsks = async function () {
    try {
      askLoading.value = true
      await until(() => userId.value).toMatch(v => v !== null)
      const gotAsks = await getUserAsks(userId.value, askPage.value, limit)
      if (askPage.value === 1) {
        asks.value = gotAsks
      } else {
        asks.value = [...asks.value, ...gotAsks]
      }
      if (gotAsks.length < limit) {
        askHasMore.value = false
      } else {
        askPage.value += 1
      }
    } catch (err) {
      console.error(err)
      showToast(err)
    } finally {
      askLoading.value = false
    }
  }

  onMounted(() => {
    getAsks()
  })

  return {
    asks,
    askPage,
    askHasMore,
    askLoading,
    getAsks,
  }
}