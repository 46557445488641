<template>
  <div class="ask-create">
    <pr-cell
      :title="$t('anonymous')"
    >
      <toggle-switch v-model="anonymous" />
    </pr-cell>

    <pr-cell :title="$t('peekPrice')">
      <prices-setter v-model="peekPrices" />
    </pr-cell>

    <p v-if="user">{{ $t('peekDescription', { nickname: user.nickname }) }}</p>

    <Textarea
      v-if="user"
      v-model="question"
      :placeholder="$t('questionPlaceholder', { nickname: user.nickname })"
      style="width: 100%; margin-top: 2rem;">
    </Textarea>

    <p v-if="user">{{ $t('askIsNoRefund', { nickname: user.nickname }) }}</p>

    <pr-button
      v-if="user"
      type="primary"
      :loading="asking"
      @click="ask">
      {{ $t('payToAsk', { price: askPriceText }) }}
    </pr-button>
  </div>
</template>

<script>
//@ts-check
import PrCell from '@/components/PRCell.vue'
import { useAskCreate } from '@/composables/ask_create'
import ToggleSwitch from 'primevue/toggleswitch'
import PricesSetter from '@/components/PricesSetter.vue'
import Textarea from 'primevue/textarea'
import PrButton from '@/components/PRButton.vue'
import { useUserPresent } from '@/composables/user_present'

export default {
  setup () {
    const userPresent = useUserPresent()
    return {
      ...userPresent,
      ...useAskCreate({ targetUserRef: userPresent.user }),
    }
  },
  components: {
    PrCell,
    ToggleSwitch,
    PricesSetter,
    Textarea,
    PrButton,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.ask-create {
  padding-top: 2rem;
  width: 30rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media @phone {
    padding: 1rem;
    width: 100%;
  }

  p {
    font-size: 0.9rem;
    color: @minor;
  }
}
</style>