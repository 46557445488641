<template>
  <pr-empty
    v-if="post && post.status === 'handling'"
    :text="$t('stillProcessing').toString()">
  </pr-empty>
  <div
    v-else-if="post && !outofservice"
    class="manga-container">
    <div
      v-show="toolbarShow"
      class="toolbars">
      <header
        @click.prevent.stop="toolbarShow = !toolbarShow">
        <div class="wrapper">
          <div
            class="info"
            style="width: 100%;">
            <span v-if="circle">{{ circle.title }}</span>
            <span>{{ localeTitle }}</span>

            <div
              v-if="circle"
              class="navigate">
              <div
                v-show="chapterTurnable"
                @click="prevChapter">{{ $t('prevChapter') }}</div>
              <div
                v-show="chapterTurnable"
                @click="nextChapter">{{ $t('nextChapter') }}</div>
            </div>
          </div>
        </div>
      </header>


      <aside
        @click.prevent.stop="toolbarShow = !toolbarShow">
        <div class="actions">
          <div
            class="btn"
            :class="{ active: post.starred }"
            @click.prevent.stop="intentStar">
            <span>
              <img
                v-if="!post.starred"
                src="@/assets/star.png">
              <img
                v-else
                src="@/assets/star_click.png">
              {{ post.hideStars ? '' : post.starCount }}
            </span>
          </div>
          <div
            class="btn"
            @click.prevent.stop="$router.push(`/comics/${post._id}/comments`)">
            <span>
              <img
                src="@/assets/comment.png">
              {{ post.commentCount }}
            </span>
          </div>
          <div
            class="btn"
            :class="{ active: post.liked }"
            @click.prevent.stop="like">
            <span>
              <img
                v-if="!post.liked"
                src="@/assets/like.png">
              <img
                v-else
                src="@/assets/like_click.png">
              {{ post.likes }}
            </span>
          </div>
          <div
            class="btn"
            @click.prevent.stop="downloadZip">
            <span>
              <img src="@/assets/icon_postdetail_download_switchoff@2x.png">
            </span>
          </div>
        </div>

        <div class="chapter-select">
          <div
            v-if="circle"
            class="btn"
            @click="chapterMenuShow = true">
            {{ $t('selectChapters') }}
          </div>
          <div
            v-else
            class="btn"
            @click="$router.push(`/users/${post.user._id}`)">
            {{ $t('selectChapters') }}
          </div>
          <div
            v-if="nextArtworkId"
            class="btn"
            @click="$router.push(`/comics/${nextArtworkId}`)">
            {{ $t('nextChapter') }}
          </div>
          <div
            v-else
            class="btn disabled">
            {{ $t('nextChapter') }}
          </div>
        </div>
      </aside>

      <footer
        @click.prevent.stop="toolbarShow = !toolbarShow">
        <div
          v-if="post.channels.length > 0"
          class="channels">
          <p>{{ $t('artworkChannel') }}</p>
          <div
            v-for="channel in post.channels"
            :key="channel._id"
            class="channel"
            @click="$router.push(`/channels/${channel._id}`)">
            {{ channel.title }}
          </div>
        </div>
      </footer>
    </div>

    <div
      class="content"
      @click="toolbarShow = !toolbarShow">
      <artwork-warmup :post="post">
        <template #default="{ warmupStatus, needsWarmup, imageStatusMap }">
          <progress-bar
            v-show="mediaLoadingProgress !== 0"
            :value="mediaLoadingProgress"
            :show-value="false">
          </progress-bar>
          <!-- mobile -->
          <div
            v-if="isOnMobile && (needsWarmup && warmupStatus === 'done' || !needsWarmup)"
            v-drag="onMediaDragged"
            class="media-container"
            @click.prevent.stop="toolbarShow = !toolbarShow">
            <img
              v-if="displayingMedia && displayingMedia.type === MediaType.IMAGE"
              ref="mediaRef"
              :style="mediaStyle"
              :src="displayingMedia.url">
            <img
              v-else-if="displayingMedia && displayingMedia.type === MediaType.VIDEO && !post.paid"
              ref="mediaRef"
              :style="mediaStyle"
              :src="displayingMedia.thum">
            <video
              v-else-if="displayingMedia && displayingMedia.type === MediaType.VIDEO && post.paid"
              ref="mediaRef"
              controlslist="nodownload"
              :src="displayingMedia.url"
              :poster="displayingMedia.thum">
            </video>
            <pr-button
              v-if="displayingMedia && !displayingMedia.canBrowse && !post.paid && post.price !== 0"
              :plain="true"
              @click.stop.prevent="unlock">
              {{ post.requiresCircleRight ? priceText : $t('payToUnlock', { price: priceText }) }}
            </pr-button>
          </div>
          <!-- desktop -->
          <div
            v-else-if="!isOnMobile && (needsWarmup && warmupStatus === 'done' || !needsWarmup)"
            class="media-container"
            @click.prevent.stop="onMediaClicked">
            <img
              v-if="displayingMedia && displayingMedia.type === MediaType.IMAGE"
              ref="mediaRef"
              :style="mediaStyle"
              :src="displayingMedia.url">
            <img
              v-else-if="displayingMedia && displayingMedia.type === MediaType.VIDEO && !post.paid"
              ref="mediaRef"
              :style="mediaStyle"
              :src="displayingMedia.thum">
            <video
              v-else-if="displayingMedia && displayingMedia.type === MediaType.VIDEO && post.paid"
              ref="mediaRef"
              controlslist="nodownload"
              :src="displayingMedia.url"
              :poster="displayingMedia.thum">
            </video>
            <pr-button
              v-if="displayingMedia && !displayingMedia.canBrowse && !post.paid && post.price !== 0"
              :plain="true"
              @click.stop.prevent="unlock">
              {{ post.requiresCircleRight ? priceText : $t('payToUnlock', { price: priceText }) }}
            </pr-button>
          </div>
          <img
            v-for="media in preloadMedias.filter(media => imageStatusMap[media._id])"
            :key="media._id"
            style="display: absolute; bottom: 0; right: 0; width: 0px; height: 0px"
            :src="media.url">
        </template>
      </artwork-warmup>
    </div>

    <media-saver
      v-model="collectorShow"
      :post="post"
      @completed="onStarCompleted"></media-saver>


    <drawer
      v-model:visible="chapterMenuShow"
      :position="isOnMobile ? 'bottom' : 'right'"
      class="menu-popup">
      <div class="chapters-header">
        <span>
          {{ circle ? circle.title : '' }}
          {{ $t('overview') }}
        </span>
        <img
          v-show="sort === 'des'"
          src="@/assets/comic-ic-comic-anticlockwise-selected@2x.png"
          @click="sort = 'asc'">
        <img
          v-show="sort === 'asc'"
          src="@/assets/ic-comic-clockwise@2x.png"
          @click="sort = 'des'">
      </div>
      <div class="chapters">
        <div
          v-for="post in circlePosts"
          :key="post._id"
          class="chapter"
          @click="$router.push(`/comics/${post._id}`)">
          <span>
            {{ post.title }}
          </span>
        </div>
      </div>
    </drawer>
  </div>
  <out-of-service-cover v-else-if="post && outofservice"></out-of-service-cover>
</template>


<script>
//@ts-check
import { useArtworkPresent } from '@/composables/artwork_present'
import { useArtworkPurchase } from '@/composables/artwork_purchase'
import { useCurrentCurrency } from '@/composables/currency'
import { useResponsive } from '@/composables/responsive'
import { useArtworkActions } from '@/composables/artwork_action'
import MediaSaver from '@/components/MediaSaver.vue'
import { useRoute } from 'vue-router'
import { useArtworkMangaOperations } from '@/composables/artwork_manga_operation'
import { useArtworkCircle } from '@/composables/artwork_circle'
import OutOfServiceCover from '@/components/OutOfServiceCover.vue'
import mixpanel from 'mixpanel-browser'
import { onMounted } from 'vue'
import PrEmpty from '@/components/PREmpty.vue'
import PrButton from '@/components/PRButton.vue'
import ProgressBar from 'primevue/progressbar'
import { MediaType } from '@/utils/defines'
import Drawer from 'primevue/drawer'
import ArtworkWarmup from '@/components/ArtworkWarmup.vue'


export default {
  setup() {
    const { currency } = useCurrentCurrency()
    const route = useRoute()
    const postId = route.params.postId
    const artworkPresentComposition = useArtworkPresent(postId, currency, null, true)
    const artworkCricleComposition = useArtworkCircle(postId)

    onMounted(() => {
      mixpanel.track('View Artwork', {
        type: 'manga',
        artworkId: postId
      })
    })

    return {
      ...artworkPresentComposition,
      ...useArtworkPurchase(postId),
      currency,
      ...useResponsive(),
      ...useArtworkActions(postId),
      ...useArtworkMangaOperations(artworkPresentComposition.post, artworkCricleComposition.circle),
      ...artworkCricleComposition,
      MediaType,
    }
  },
  components: {
    MediaSaver,
    OutOfServiceCover,
    PrEmpty,
    PrButton,
    ProgressBar,
    Drawer,
    ArtworkWarmup,
  },
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.p-progressbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  line-height: 8px;
  z-index: 3;
}

.manga-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100vh;
  height: 100dvh;

  @media @phone {
    .manga-container {
      width: 100%;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @grey;

    > span {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
    }

    &.active {
      color: @main;
    }

    &.disabled {
      opacity: 0.77;
      filter: brightness(0.8);

      &:hover {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }

    &:disabled:hover {
      cursor: not-allowed;
      filter: none;
    }
  }

  .menu-popup {
    background-color: #282828;
    width: 20em;
    height: 100%;
    border-radius: 0.5em;
    color: @warm-grey;
    font-weight: bolder;
    overflow: hidden;

    @media @phone {
      width: 100%;
      height: 30em;
    }

    .chapters-header {
      display: flex;
      height: 2em;
      align-items: center;
      padding-left: 0.5em;
      padding-right: 1em;
      box-sizing: border-box;
      background-color: #353535;
      margin: 2px;

      span {
        font-size: 14px;
      }

      img {
        height: 1.1em;
        margin-left: auto;

        &:hover {
          cursor: pointer;
          filter: brightness(0.8)
        }
      }
    }

    .chapters {
      height: calc(30em - 2em - 1px);
      overflow-y: scroll;

      .scrollbar(@warm-grey, transparent);

      .chapter {
        background-color: #353535;
        margin: 2px;
        padding-left: 0.5em;
        padding-right: 1em;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
          filter: brightness(0.8)
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .chapter-select .btn {
    color: #9b9b9b;
  }


  header, aside, footer {
    position: fixed;
    color: @grey;
    z-index: 1;
  }

  header {
    height: 3.5em;
    background-color: rgba(0, 0, 0, 0.97);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    user-select: none;

    .wrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
    }
    
    .info {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      padding-left: 1rem;
      padding-right: 1rem;

      > span {
        font-size: 16px;
        margin-right: 1em;
      }

      .navigate {
        display: flex;
        margin-left: auto;
        justify-content: center;
        align-items: center;

        div {
          margin-left: 1.5em;

          &:hover {
            cursor: pointer;
            filter: brightness(0.8);
          }
        }
      }
    }
  }

  @media @phone {
    header {
      .info {
        position: inherit;
        left: auto;
        transform: none;

        span {
          display: flex;
        }
      }
    }
  }

  aside, footer {
    background-color: rgba(0, 0, 0, 0);
    bottom: 0;
  }

  footer {
    left: 0;
    right: 0;
  }

  aside {
    top: 3.5rem;
    right: 0;
    width: 10em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;

    .btn {
      background-color: @light-black;
      width: 130px;
      height: 30px;
      border-radius: 4px;
      opacity: 0.8;
      margin-bottom: 10px;
    }

    .actions {
      margin-bottom: 2em;

      img {
        width: 16px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      user-select: none;
      object-fit: contain;
    }
  }
}

.media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  button {
    position: absolute;
    margin-top: auto;
    color: white;
    border-color: white;
    font-size: 0.8em;
    white-space: nowrap;
  }
}

.channels {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;

  @media @phone {
    width: calc(100% - 10em);
  }

  p {
    padding-left: 0.6em;
    margin-bottom: 0.5em;
    font-size: 14px;
    color: @grey;
  }

  .channel {
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #9b9b9b;
    border-radius: 4px;
    margin-left: 0.7em;
    margin-bottom: 0.3em;
    background: @light-black;

    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.65;
    }
  }
}
</style>
