<template>
  <div>
    <div
      class="price-container hoverable"
      @click.prevent="preparePopupToggle">
      <span v-if="defaultCurrencyModifieable">{{ defaultCurrency }}</span>
      <span v-else>{{ latestEditingCurrency }}</span>
      <span
        v-if="defaultCurrencyModifieable"
        class="price">
        {{ price > 0 ? price : 'Free' }}
      </span>
      <span
        v-else
        class="price">
        {{ prices[latestEditingCurrency] > 0 ? prices[latestEditingCurrency] : 'Unset' }}
      </span>
    </div>

    <pr-popup
      v-model="showPopup"
      class="price-setter-popup">
      <template #header>
        Global Prices
      </template>
      <div class="price-containers">
        <div
          v-for="currency in Object.keys(prices)"
          :key="currency"
          class="price-container">
          <span
            v-if="defaultCurrencyModifieable"
            class="hoverable"
            @click.prevent="changeDefaultCurrency(currency)">
            {{ currency }} {{ defaultCurrency === currency ? '(Default)' : '' }}
          </span>
          <span v-else>
            {{ currency }}
          </span>
          <input
            v-if="currency === 'JPY'"
            v-model="prices[currency]"
            class="price"
            step="1"
            type="number"
            :min="mins[currency]"
            @change="onPriceUpdated(currency)"
            @input="latestEditingCurrency = currency"
            @keypress="isNumberKeyPrice" />
          <input
            v-else
            v-model="prices[currency]"
            class="price"
            type="number"
            step="0.01"
            :min="mins[currency]"
            @change="onPriceUpdated(currency)"
            @input="latestEditingCurrency = currency"
            @keypress="isNumberKeyPrice" />
        </div>
      </div>
      <pr-cell
        v-if="defaultCurrencyModifieable"
        style="margin-top: 1rem;"
        title="Default Currency">
        <Select
          v-model="defaultCurrency"
          :options="currencyOptions"
          option-label="label"
          option-value="value"
          size="small">
        </Select>
      </pr-cell>
      <template #footer>
        <div class="bottom">
          <pr-button
            @click.prevent="autoSet">{{ autoSetButtonText }}</pr-button>
          <pr-button
            v-show="showFreeButton"
            @click.prevent="reset">{{ $t('setFree') }}</pr-button>
          <pr-button
            type="primary"
            @click.prevent="confirm">{{ $t('confirm') }}</pr-button>
        </div>
      </template>
    </pr-popup>
  </div>
</template>

<script>
//@ts-check
import { computed, ref, watch } from 'vue'
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useI18n } from 'vue-i18n'
import { useCurrentUser } from '@/composables/user_data'
import { exchange } from '@/api/exchange'
import { useToast } from '@/composables/toast'
import { allowPirate } from '@/config'
import PrPopup from '@/components/PRPopup.vue'
import { useCurrentCurrency } from '@/composables/currency'
import Select from 'primevue/select'
import { currencyOptions } from '@/composables/issue_create'
import PrCell from '@/components/PRCell.vue'

const mins = {
  CNY: 4,
  USD: 0.5,
  EUR: 0.5,
  JPY: 75,
}


export default {
  props: {
    priceable: {
      type: Boolean,
      default: true,
    },
    showFreeButton: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Object,
      required: true,
      default () {
        return {
          price: 0,
          defaultCurrency: 'JPY',
          prices: {
            CNY: 0,
            JPY: 0,
            EUR: 0,
            USD: 0,
          }
        }
      }
    }
  },
  emits: ['update:modelValue', 'close', 'done'],
  setup (props, { emit }) {
    const price = ref(props.modelValue.price || 0)
    const prices = ref(props.modelValue.prices)
    const { currency } = useCurrentCurrency()
    const defaultCurrency = ref(props.modelValue.defaultCurrency ?? currency.value)
    const size = ref(5)
    const showPopup = ref(false)
    const latestEditingCurrency = ref(defaultCurrency.value)
    const fetchingRates = ref(false)
    const { t } = useI18n()
    const { currentUser } = useCurrentUser()
    const { Toast } = useToast()

    const defaultCurrencyModifieable = computed(() => {
      return !!props.modelValue.defaultCurrency
    })

    const autoSetButtonText = computed(() => {
      if (fetchingRates.value) {
        return 'Exchanging...'
      }

      return t('autoSetRest', { currency: defaultCurrency.value })
    })

    watch(props, () => {
      price.value = props.modelValue.price
      prices.value = props.modelValue.prices
      defaultCurrency.value = props.modelValue.defaultCurrency
    })

    watch(price, () => {
      if (!price.value) {
        return
      }

      const length = price.value.length
      if (length === 0) {
        size.value = 5
      } else if (length <= 4) {
        size.value = 4
      } else {
        size.value = length
      }
    })

    watch(showPopup, () => {
      if (!showPopup.value) {
        emit('done')
      }
    })

    const preparePopupToggle = function () {
      if (!showPopup.value) {
        if (!currentUser.value.authed && !allowPirate) {
          return Toast({ message: 'only verified artist can publish paid artworks' }, 'info')
        }
      }

      showPopup.value = !showPopup.value
    }

    const updateValue = function () {
      for (const currency of Object.keys(prices.value)) {
        prices.value[currency] = Number(prices.value[currency])
      }
      if (defaultCurrencyModifieable.value) {
        emit('update:modelValue', {
          defaultCurrency: defaultCurrency.value,
          price: price.value,
          prices: prices.value,
        })
      } else {
        emit('update:modelValue', {
          prices: prices.value,
        })
      }
    }

    const setPriceFor = async function (currency) {
      const rates = await exchange(currency)

      if (defaultCurrencyModifieable.value) {
        const rate = rates[defaultCurrency.value]

        if (currency === 'JPY') {
          prices.value[currency] = Math.round(prices.value[defaultCurrency.value] / rate)
        } else {
          prices.value[currency] = (prices.value[defaultCurrency.value] / rate).toFixed(2)
        }
      } else {
        const rate = rates[latestEditingCurrency.value]

        if (currency === 'JPY') {
          prices.value[currency] = Math.round(prices.value[latestEditingCurrency.value] / rate)
        } else {
          prices.value[currency] = (prices.value[latestEditingCurrency.value] / rate).toFixed(2)
        }
      }
    }

    const reset = function () {
      price.value = 0
      for (const currency of Object.keys(prices.value)) {
        prices.value[currency] = 0
      }
      updateValue()
    }

    const autoSet = async function () {
      try {
        if (prices.value[defaultCurrency.value] === 0 && defaultCurrencyModifieable.value) {
          throw new Error(`${defaultCurrency.value} price is zero`)
        }

        const promises = []
        fetchingRates.value = true
        for (const currency of Object.keys(prices.value)) {
          promises.push(setPriceFor(currency))
        }

        await Promise.all(promises)

        fetchingRates.value = false
        if (defaultCurrencyModifieable.value) {
          price.value = prices.value[defaultCurrency.value]          
        }
        updateValue()
      } catch (err) {
        Toast({
          message: err.message
        })
      } finally {
        fetchingRates.value = false
      }
    }

    const confirm = function () {
      let allZero = true
      let constrainsBreak = false
      const tooSmallCurrency = []

      for (const currency of Object.keys(prices.value)) {
        const _price = Number(prices.value[currency])
        if (_price !== 0) {
          allZero = false
        }

        if (mins[currency] > _price) {
          tooSmallCurrency.push(currency)
          constrainsBreak = true
        }
      }

      if (!allZero && constrainsBreak) {
        Toast({
          message: `${tooSmallCurrency.join(', ')} price too cheap`,
        }, 'warn')
        return
      }

      showPopup.value = false
      if (defaultCurrencyModifieable.value) {
        price.value = prices.value[defaultCurrency.value]          
      }

      updateValue()
    }

    const changeDefaultCurrency = function (currency) {
      defaultCurrency.value = currency
      price.value = prices.value[currency]
      latestEditingCurrency.value = currency
      updateValue()
    }

    const isNumberKeyPrice = function (evt) {
      if (!props.priceable) {
        evt.preventDefault()
        return false
      }

      if (evt.key === '.') {
        return true
      }

      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
        return false
      }

      return true
    }

    const onPriceUpdated = function (currency) {
      prices.value[currency] = Math.max(mins[currency], Number(prices.value[currency]))
    }

    return {
      price,
      prices,
      defaultCurrency,
      size,
      isNumberKeyPrice,
      showPopup,
      autoSet,
      confirm,
      latestEditingCurrency,
      changeDefaultCurrency,
      autoSetButtonText,
      reset,
      defaultCurrencyModifieable,
      ...useResponsive(),
      mins,
      onPriceUpdated,
      preparePopupToggle,
      currencyOptions,
    }
  },
  components: {
    PrButton,
    PrPopup,
    Select,
    PrCell,
  }
}
</script>

<style>
.price-setter-popup.p-dialog {
  width: 20rem;
}

.price-setter-popup.p-drawer {
  height: 24rem !important;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.price-containers {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  border: 2px solid #FFC951;
  padding: 0.1em 0.5em 0 0.5em;
  min-width: 6.5rem;
}

.price-container span {
  display: block;
  text-align: left;
  float: left;
}

.price-container span {
  color: #FFC951;
  font-size: 0.9em;
}

.bottom {
  width: 100%;
}

.bottom button {
  width: 100%;
  margin-top: 0.5em;
}

input.price {
  display: inline;
  height: 1.6em;
  outline: none;
  border: none;
  text-align: right;
  color: #FFC951;
  flex-grow: 1;
}

span.price {
  margin-left: auto;
}
</style>