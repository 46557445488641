<template>
  <Button
    :class="{
      default: type === 'default',
      positive: type === 'positive',
      primary: type === 'primary',
      blue: type === 'blue',
      flat: flat,
      plain: plain,
      'p-button-outlined': plain,
      'p-button-sm': size === 'small',
      'p-button-lg': size === 'large',
    }"
    :severity="type === 'default' ? 'secondary' : undefined"
    :disabled="disabled || loading">
    <vue-feather
      v-if="loading"
      class="animation-spin icon"
      type="refresh-cw"></vue-feather>
    <slot></slot>
  </Button>
</template>

<script>
import VueFeather from 'vue-feather'
import Button from 'primevue/button'

export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'normal'
    },
    flat: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    VueFeather,
    Button,
  }
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

button:hover {
  cursor: pointer;
}

button {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-button.primary:enabled:hover {
  background-color: @main;
  color: white;
}

.p-button.primary:enabled:active {
  background-color: @main;
  border-color: transparent;
}

button.flat {
  box-shadow: none;
}

button:active {
  box-shadow: none !important;
}

button[disabled] {
  opacity: 0.54;
  box-shadow: none;

  &:hover {
    cursor: default;
  }
}

.primary {
  background-color: @main;
  color: white;
}

.positive {
  background-color: @positive;
  color: white;
}

.blue {
  border-radius: 50% / 100%;
  color: @blue;
  border: solid 1px @blue !important;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;

  &:hover {
    color: white !important;
    background: @blue !important;
  }
}

.vue-feather {
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -4px;
}

.primary .vue-feather {
  color: white;
}

.animation-spin {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
</style>
