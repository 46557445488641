//@ts-check
import Subscription from '@/pages/channels/index.vue'
import ChannelSubmit from '@/pages/channels/_id/submit.vue'
import ChannelReview from '@/pages/channels/_id/review.vue'
import ChannelSingleEdit from '@/pages/channels/_id/edit.vue'

import Circle from '@/pages/circles/index.vue'


import Me from '@/pages/i/index.vue'

import SearchResult from '@/pages/search/index.vue'

import Works from '@/pages/i/profile.vue'
import Channel from '@/pages/channels/_id/index.vue'
import ChannelUsers from '@/pages/channels/_id/users.vue'
import ChannelEdit from '@/pages/channels/edit.vue'

import MyCircles from '@/pages/circles/my.vue'
import AllCircles from '@/pages/circles/all.vue'
import UserCircles from '@/pages/users/_id/circles.vue'
import PhotoCircles from '@/pages/circles/photos.vue'
import ComicCircles from '@/pages/circles/comics.vue'
import CircleMember from '@/pages/circles/_id/members.vue'
import CircleTag from '@/pages/circles/_id/tag.vue'
import CircleGrant from '@/pages/circles/_id/grant.vue'
import CircleEdit from '@/pages/circles/_id/edit.vue'

//漫画
import Comic from '@/pages/comics/_id/index.vue'
import ComicComment from '@/pages/comics/_id/comments.vue'

import CirclePortal from '@/pages/circles/_id/index.vue'
import CircleReadme from '@/pages/circles/_id/readme.vue'
import Download from '@/pages/Download.vue'
import AboutUs from '@/pages/AboutUs.vue'
import ContactUs from '@/pages/ContactUs.vue'
import Product from '@/pages/Production.vue'
import Serverce from '@/pages/Server.vue'
import Checkout from '@/pages/checkout.vue'


import Explore from '@/pages/explore/index.vue'
import Topic from '@/pages/topics/_id.vue' 
import Topics from '@/pages/topics/index.vue' 
import ExploreUser from '@/pages/explore/user.vue' 

import Followers from '@/pages/users/_id/followers.vue'
import Followings from '@/pages/users/_id/followings.vue'

import Stars from '@/pages/stars/index.vue'
import StarsDetail from '@/pages/stars/_id/index.vue'
import PostDetail from '@/pages/posts/_id/index.vue'
import PostBuyer from '@/pages/posts/_id/buyers.vue'
import PostEdit from '@/pages/posts/_id/edit.vue'

import Result from '@/pages/result.vue'
import Rules from '@/pages/Rules.vue'
import UserPage from '@/pages/_username.vue'

import ReportCenter from '@/pages/reports/index.vue'
import ReportDetail from '@/pages/reports/_id.vue'

import Wallet from '@/pages/i/wallet/index.vue'
import WalletBind from '@/pages/i/wallet/bind/index.vue'
import WalletBindResult from '@/pages/i/wallet/bind/result.vue'
import WalletUSDT from '@/pages/i/wallet/usdt.vue'
import Setting from '@/pages/i/settings/index.vue'
import NotificationSetting from '@/pages/i/settings/notification.vue'
import BlacklistSetting from '@/pages/i/settings/blacklist.vue'
import Orders from '@/pages/i/orders/index.vue'
import Downloads from '@/pages/i/downloads/index.vue'

import Publisher from '@/pages/i/publisher/index.vue'
import UserPosts from '@/pages/users/_id/index.vue'
import UserIssues from '@/pages/users/_id/commissions/index.vue'
import IssueCreate from '@/pages/users/_id/commissions/create.vue'
import UserAsks from '@/pages/users/_id/asks/index.vue'
import AskCreate from '@/pages/users/_id/asks/create.vue'

import Publish from '@/pages/i/publisher/publish/index.vue'

import VerifyApply from '@/pages/i/publisher/verify/index.vue'
import VerifyResult from '@/pages/i/publisher/verify/result.vue'
import CircleApply from '@/pages/i/publisher/circles/apply.vue'
import ChannelManage from '@/pages/i/publisher/channels/index.vue'
import ChannelCreate from '@/pages/i/publisher/channels/create.vue'
import ChannelMyEdit from '@/pages/i/publisher/channels/_id/index.vue'
import SelfTag from '@/pages/i/publisher/tags/index.vue'
import SelfTagDetail from '@/pages/i/publisher/tags/_tagId.vue'
import Requester from '@/pages/i/publisher/requesters.vue'
import DiscountCodes from '@/pages/i/publisher/discountCodes/index.vue'
import DiscountCodesCreate from '@/pages/i/publisher/discountCodes/create.vue'

import Promote from '@/pages/i/publisher/promote/index.vue'
import PromoteDiscount from '@/pages/i/publisher/promote/discount.vue'
import PromoteSubmit from '@/pages/i/publisher/promote/submit.vue'
import PromoteChannel from '@/pages/i/publisher/promote/channel.vue'
import PromoteCowork from '@/pages/i/publisher/promote/cowork/requests.vue'
import PromoteBookApp from '@/pages/i/publisher/promote/book/app.vue'
import PromoteBookChannel from '@/pages/i/publisher/promote/book/channel.vue'
import PromoteBookCreate from '@/pages/i/publisher/promote/book/create.vue'

import PromoteTopic from '@/pages/i/publisher/promote/topics/index.vue'
import PromoteTopicContribute from '@/pages/i/publisher/promote/topics/_id/contribute.vue'
import PromoteFeatured from '@/pages/i/publisher/promote/featured/index.vue'
import PromoteFeaturedContribute from '@/pages/i/publisher/promote/featured/contribute.vue'
import PromoteRecruit from '@/pages/i/publisher/promote/submissions/index.vue'

import IssueDetail from '@/pages/commissions/_id.vue'
import AskDetail from '@/pages/asks/_id.vue'

import Report from '@/pages/Report.vue'

import EditProfile from '@/pages/i/edit/index.vue'
import EditPhone from '@/pages/i/edit/phone.vue'
import EditAutoreply from '@/pages/i/edit/autoreply.vue'
import EditEmail from '@/pages/i/edit/email.vue'
import EditPassword from '@/pages/i/edit/password.vue'

import Notification from '@/pages/i/notifications/index.vue'
import NotificationDetail from '@/pages/i/notifications/_id.vue'
import Disclosure from '@/pages/disclosure.vue'
import Privacy from '@/pages/privacy.vue'
import Terms from '@/pages/terms.vue'
import Create from '@/pages/create.vue'
import { isOnMobile } from '@/utils'

const routes = [{
  path: '/circles',
  component: Circle,
  name: 'CircleList',
  children: [{
    path: 'my',
    component: MyCircles,
    name: 'CircleListMine',
    meta: { keepAlive: true }
  }, {
    path: 'all',
    name: 'CircleListAll',
    component: AllCircles,
    meta: { keepAlive: true }
  }, {
    path: 'comics',
    component: ComicCircles,
    name: 'CircleListComics'
  }, {
    path: 'photos',
    component: PhotoCircles,
    name: 'CircleListPhotos'
  }, {
    path: '/circles/:circleId',
    component: CirclePortal,
    meta: {
      keepAlive: true,
    }
  }, {
    path: '/circles/:circleId/readme',
    component: CircleReadme,
    meta: {
      keepAlive: true,
    }
  }, {
    path: '/circles/:circleId/members',
    component: CircleMember,
  }, {
    path: '/circles/:circleId/tag',
    component: CircleTag,
  }, {
    path: '/circles/:circleId/grant',
    component: CircleGrant,
  }, {
    path: '/circles/:circleId/edit',
    component: CircleEdit,
  }]
}, {
  path: '/explore',
  component: Explore,
  meta: { keepAlive: true }
}, {
  path: '/explore/user',
  component: ExploreUser,
}, {
  path: '/stars',
  component: Stars,
  meta: { keepAlive: true }
}, {
  path: '/stars/:ownerId',
  component: StarsDetail
}, {
  path: '/posts/:postId',
  component: PostDetail
}, {
  path: '/posts/:postId/buyers',
  component: PostBuyer,
}, {
  path: '/posts/:postId/edit',
  component: PostEdit,
}, {
  path: '/search',
  component: SearchResult
}, {
  path: '/about-us',
  component: AboutUs
}, {
  path: '/production',
  component: Product
}, {
  path: '/service',
  component: Serverce
}, {
  path: '/contactus',
  component: ContactUs
}, {
  path: '/i/',
  component: Me,
  meta: { keepAlive: true },
  children: [{
    path: 'profile',
    component: Works,
    name: '/i/profile',
    meta: { keepAlive: true },
  }, {
    path: 'wallet',
    component: Wallet
  }, {
    path: 'wallet/bind',
    component: WalletBind,
  }, {
    path: 'wallet/bind/result',
    component: WalletBindResult,
  }, {
    path: 'wallet/usdt',
    component: WalletUSDT,
  }, {
    path: 'edit',
    component: EditProfile,
    name: 'editprofile',
  }, {
    path: 'edit/phone',
    component: EditPhone,
  }, {
    path: 'edit/autoreply',
    component: EditAutoreply,
  }, {
    path: 'edit/email',
    component: EditEmail,
  }, {
    path: 'edit/password',
    component: EditPassword,
  }, {
    path: 'publisher/channels',
    component: ChannelManage,
  }, {
    path: 'publisher/channels/create',
    component: ChannelCreate,
  }, {
    path: 'publisher/channels/:channelId',
    component: ChannelMyEdit,
  }, {
    path: 'publisher/verify',
    name: 'verifyapply',
    component: VerifyApply
  }, {
    path: 'publisher/verify/result',
    name: 'verifyresult',
    component: VerifyResult,
  }, {
    path: 'publisher/circles/apply',
    component: CircleApply,
    name: 'circleapply',
  }, {
    path: 'publisher/publish',
    component: Publish,
  }, {
    path: 'publisher/discountCodes',
    component: DiscountCodes,
  }, {
    path: 'publisher/discountCodes/create',
    component: DiscountCodesCreate,
  }, {
    path: 'publisher/promote',
    component: Promote
  }, {
    path: 'publisher/promote/discount',
    component: PromoteDiscount
  }, {
    path: 'publisher/promote/submit',
    component: PromoteSubmit
  }, {
    path: 'publisher/promote/channel',
    component: PromoteChannel
  }, {
    path: 'publisher/promote/book/app',
    component: PromoteBookApp
  }, {
    path: 'publisher/promote/book/channel',
    component: PromoteBookChannel
  }, {
    path: 'publisher/promote/book/create',
    component: PromoteBookCreate
  }, {
    path: 'publisher/promote/coworker/requests',
    component: PromoteCowork
  }, {
    path: 'publisher/promote/topics',
    component: PromoteTopic
  }, {
    path: 'publisher/promote/topics/:topicId/contribute',
    component: PromoteTopicContribute
  }, {
    path: 'publisher/promote/featured',
    component: PromoteFeatured
  }, {
    path: 'publisher/promote/featured/contribute',
    component: PromoteFeaturedContribute
  }, {
    path: 'publisher/promote/submissions',
    component: PromoteRecruit
  }, {
    path: 'publisher/tags',
    component: SelfTag
  }, {
    path: 'publisher/tags/:tagId',
    component: SelfTagDetail
  }, {
    path: 'publisher/requesters',
    component: Requester
  }, {
    path: 'publisher',
    component: Publisher,
  }, {
    path: 'settings/notification',
    component: NotificationSetting,
  }, {
    path: 'settings/blacklist',
    component: BlacklistSetting,
  }, {
    path: 'settings',
    component: Setting,
  }, {
    path: 'notifications',
    component: Notification,
    name: 'notifications',
  }, {
    path: 'notifications/:notificationId',
    component: NotificationDetail,
  }, {
    path: 'orders',
    component: Orders
  }, {
    path: 'downloads',
    component: Downloads
  }, {
    path: '*',
    redirect: ''
  }]
}, {
  path: '/users/:userId',
  component: UserPosts,
  meta: { keepAlive: true },
}, {
  path: '/users/:userId/followers',
  component: Followers
}, {
  path: '/users/:userId/followings',
  component: Followings
}, {
  path: '/users/:userId/commissions',
  component: UserIssues,
}, {
  path: '/users/:userId/commissions/create',
  component: IssueCreate,
}, {
  path: '/users/:userId/asks',
  component: UserAsks,
}, {
  path: '/users/:userId/asks/create',
  component: AskCreate,
}, {
  path: '/result',
  component: Result
}, {
  path: '/channels',
  component: Subscription,
  meta: { keepAlive: true },
}, {
  path: '/channels/edit',
  component: ChannelEdit,
}, {
  path: '/channels/:channelId',
  component: Channel
}, {
  path: '/channels/:channelId/users',
  component: ChannelUsers
}, {
  path: '/channels/:channelId/submit',
  component: ChannelSubmit,
}, {
  path: '/channels/:channelId/review',
  component: ChannelReview,
}, {
  path: '/channels/:channelId/edit',
  component: ChannelSingleEdit,
}, {
  path: '/rules',
  component: Rules
}, {
  path: '/reportcenter',
  component: ReportCenter,
  meta: { keepAlive: true }
}, {
  path: '/reportcenter/:reportId',
  component: ReportDetail,
}, {
  path: '/topics',
  component: Topics,
  meta: { keepAlive: true }
}, {
  path: '/topics/:topicId',
  component: Topic,
}, {
  path: '/report',
  component: Report
}, {
  path: '/posts/:postId/report',
  component: Report
}, {
  path: '/download',
  component: Download
}, {
  path: '/comics/:postId',
  component: Comic,
  name: 'comic',
}, {
  path: '/comics/:postId/comments',
  component: ComicComment
}, {
  path: '/user/:userId/circles',
  component: UserCircles
}, {
  path: '/checkout',
  name: 'checkout',
  component: Checkout,
}, {
  path: '/disclosure',
  name: 'disclosure',
  component: Disclosure,
}, {
  path: '/privacy',
  name: 'privacy',
  component: Privacy,
}, {
  path: '/terms',
  name: 'terms',
  component: Terms,
}, {
  path: '/create',
  component: Create,
}, {
  path: '/:username',
  name: 'userpage',
  component: UserPage,
}, {
  path: '/commissions/:issueId',
  name: 'issueDetail',
  component: IssueDetail,
}, {
  path: '/asks/:askId',
  component: AskDetail,
}]


if (isOnMobile()) {
  routes.unshift({
    path: '/',
    // @ts-ignore
    redirect: '/channels'
  }, {
    path: '/circles/all',
    component: Circle,
    meta: {
      keepAlive: true,
    }
  }, {
    path: '/circles/:circleId',
    component: CirclePortal,
    meta: {
      keepAlive: true,
    }
  }, {
    path: '/circles/:circleId/readme',
    component: CircleReadme,
    meta: {
      keepAlive: true,
    }
  }, {
    path: '/circles/:circleId/members',
    component: CircleMember,
  }, {
    path: '/circles/:circleId/tag',
    component: CircleTag,
  }, {
    path: '/circles/:circleId/grant',
    component: CircleGrant,
  }, {
    path: '/circles/:circleId/edit',
    component: CircleEdit,
  })
} else {
  routes.unshift({
    path: '/',
    // @ts-ignore
    redirect: '/channels'
  })
}


export default routes
