//@ts-check
const host = `${location.protocol}//${location.hostname}` + (location.port ? `:${location.port}` : '')

const serverEndpoint = host.includes('pero.app') ? 'https://api.pero.app' : 'https://api.perohub.com'
// export const API_URL = process.env.NODE_ENV === 'production' ? serverEndpoint : 'http://localhost:3000'
export const API_URL = serverEndpoint
export const APK_URL = process.env.NODE_ENV === 'production' ? 'https://api.prshe.im/v1' : 'http://localhost:3100/v1'


export const APP_URL = host

export const clientId = {
  mobile: '3f0a89c399277587a0a485595fae711d',
  desktop: 'b3136e0e6011d41b6eed7c335226b814',
}
export const clientSecret = {
  mobile: '93867e27fd9fa79f89e9353f7950ee67',
  desktop: 'a38b6b0531ffaab49cd95c0a82734b60',
}
export const IOS_URL = 'https://itunes.apple.com/us/app/prcircle/id1307635810?l=zh&ls=1&mt=8'
export const IOS_URL_Download = 'https://itunes.apple.com/us/app/prcircle/id1307635810?'
export const IOS_IPA_URL = 'https://neicebao.com/build/5ac47e336f60e340731cea7a'
export const ANDROID_URL = 'https://dn-tinydust.qbox.me/builds/pero-latest.apk'
export const ANDROID_STORE_URL = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.tinydust.prshe'
export const PAYPAL_ENV = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'

export const StripePublishableKey = 'pk_live_51MVrrWGZjdJNev87bbepbB7g1OkR6VS5U1Qz4JSNBrFcm77DOchIImRMQtuLtXSTz40WfsRdbfQiNaMZvZX3qj9G00HZzMm6Yx'

export const regionCodes = ['jp', 'kr', 'cn', 'us', 'hk', 'tw', 'mo', 'sg', 'ca', 'mx', 'br', 'ar', 'in', 'id', 'ru', 'tr', 'de', 'gb', 'fr', 'it', 'za', 'sa', 'au', 'eu', 'nz', 'my', 'ph', 'th', 'vn', 'bd', 'pk', 'lk', 'mm', 'kh', 'np', 'la', 'bt', 'mn', 'fj', 'pg', 'sb', 'vu']

export const officialUserId = '6452266be6072d756ae2918b'

export const cdn = {
  global: 'https://cdn.perohub.com',
  hk: 'https://cdn.pero.app',
}

export const paypalSandClientId = 'AVO3Mg5DqATAlc393Hl9WcE8jjnp7vjAm5MogBz15vT7oRrla8gEh_MNTBcyJHArzHZQyD0TglSy3PHq'
export const paypalProdClientId = 'ATK2CC3mlAPAPjOKSOenaxOHOhEKfQNVVv_-I3PFsbj-EPIZfV-YBf33moHQyNmYXZIFnlIVM_e-PDfc'
export const paypalMerchantId = process.env.NODE_ENV === 'production' ? '4XFG9L5AKM3YS' : '3GDQL7ZDZ48QC'
export const untrustLimit = 90


export const onesignal = {
  pageJSUrl: 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js',
  domains: {
    'perohub.com': {
      appId: 'cfd87a1f-a7d7-4dc6-81ff-aaa6a504bcfc',
      safariWebId: 'web.onesignal.auto.477dedc8-8bcf-40fd-b64c-238033111672',
    },
    'pero.app': {
      appId: 'b20e6386-687f-437f-8df0-8f926b2d709b',
      safariWebId: 'web.onesignal.auto.06bdf785-23ca-464d-875b-535e8684e36a',
    },
    'localhost': {
      appId: 'c358a141-95d9-4187-9507-86bfba50b2b8',
      safariWebId: 'web.onesignal.auto.52bd6d36-ef00-42e1-a687-b4f3eaae4ff3',
    }
  }
}


export const mixpanelToken = '095a7fbc669098d91c1e72d337beeb0e'

export const allowPirate = true

export const VeriffAPIKey = process.env.NODE_ENV === 'production' ? '9ee20733-bd99-4ce9-9dec-2f7f8ea86e99' : 'd9ef07e4-8179-461c-b2a2-8506e34c724f'

// export const streamingUrlRTC = process.env.NODE_ENV === 'production' ? 'https://renderer.perohub.com' : 'http://localhost:3000'
export const streamingUrlRTC = 'https://rtc.renderer.perohub.com'

// export const streamingUrlWS = process.env.NODE_ENV === 'production' ? 'wss://ws.renderer.perohub.com' : 'ws://localhost:3001'
export const streamingUrlWS = 'wss://ws.renderer.perohub.com'

export const maxRejectionTimes = 3