//@ts-check
import { onMounted, ref } from "vue"
import { getOrders } from "@/api/order"
import { useApplication } from "@/pinia/application"
import { useToast } from "./toast"


const limit = 5

export const useOrders = function () {
  const orders = ref([])
  const page = ref(1)
  const hasMore = ref(true)
  const loading = ref(false)
  const application = useApplication()
  const { Toast } = useToast()

  const get = async function () {
    try {
      loading.value = true
      orders.value.push(...await getOrders(page.value, limit))
      page.value += 1
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const loadMore = async function () {
    const beforeCount = orders.value.length
    await get()
    const afterCount = orders.value.length
    if (beforeCount === afterCount) {
      hasMore.value = false
    } else {
      hasMore.value = true
    }
  }

  const pay = async function (orderId) {
    try {
      application.openPaymentPicker({
        orderId,
        processors: ['order2faka', 'alipay', 'wechat']
      })
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const getOrderTitle = function (order) {
    if (order.type === 'circle' && order.circle) {
      if (order.circle.title) {
        return order.circle.title        
      }

      return `Circle of ${order.user.nickname}`
    } else if (order.type === 'browse' && order.artwork) {
      if (order.artwork.title) {
        return order.artwork.title        
      }

      return `Artwork by ${order.user.nickname}`
    } else if (order.type === 'issue' && order.issue) {
      return `Commission ${order.issue.title}`
    } else if (order.type === 'issue-bargain' && order.issue) {
      return `Commission ${order.issue.title} bargain fee`
    }
  }


  onMounted(() => {
    page.value = 1
    hasMore.value = true
    get()
  })

  return {
    orders,
    page,
    hasMore,
    loading,
    loadMore,
    pay,
    getOrderTitle,
  }
}