// @ts-check
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { addSelfTag, getSelfTag, getSelfTags, removeSelfTag, updateSelfTag } from "@/api/tag"
import { useRoute, useRouter } from "vue-router"
import { useArtworkStore } from "@/pinia/artwork"
import { useSortable, moveArrayElement } from '@vueuse/integrations/useSortable'
import { useToast } from "./toast"
import { whenever } from "@vueuse/core"


export const useSelfTagList = function (userIdRef) {
  const tags = ref([])
  const getting = ref(false)
  const { Toast } = useToast()

  const getTags = async function () {
    try {
      if (tags.value.length !== 0) {
        return
      }

      getting.value = true
      tags.value = await getSelfTags(userIdRef.value)
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    } finally {
      getting.value = false
    }
  }

  whenever(userIdRef, () => {
    getTags()
  }, { immediate: true })

  return {
    tags,
    getting,
  }
}


export const useSelfTagAdd = function () {
  const showAdder = ref(false)
  const title = ref('')
  const adding = ref(false)
  const router = useRouter()
  const { Toast } = useToast()

  const addTag = async function () {
    try {
      adding.value = true
      const newTag = await addSelfTag(title.value)
      showAdder.value = false
      nextTick(() => {
        router.push(`/i/publisher/tags/${newTag._id}`)
      })
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      adding.value = false
    }
  }

  return {
    adding,
    addTag,
    title,
    showAdder,
  }
}


export const useSelfTagPresent = function () {
  const tag = ref(null)
  const tagId = useRoute().params.tagId
  const artworkStore = useArtworkStore()
  const getting = ref(false)
  const { Toast } = useToast()

  const breadcrumbs = computed(() => {
    if (!tag.value) {
      return []
    }

    return [{
      label: tag.value.title
    }]
  })

  const artworks = computed(() => {
    if (!tag.value) {
      return []
    }

    return tag.value.artworkIds.map(artworkId => artworkStore.artworks[artworkId])
  })

  const getTag = async function () {
    try {
      getting.value = true
      tag.value = await getSelfTag(tagId)
      tag.value.artworkIds.forEach(artworkId => {
        artworkStore.getArtworkById(artworkId)
      })
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      getting.value = false
    }
  }

  onMounted(getTag)

  return {
    tag,
    artworks,
    getTag,
    getting,
    breadcrumbs,
  }
}


export const useSelfTagUpdate = function (tagRef, getTag) {
  const showAdder = ref(false)
  const updating = ref(false)
  const removing = ref(false)
  const artworksRef = ref(null)
  const title = ref(tagRef.value ? tagRef.value.title : '')
  const showEditing = ref(false)
  const tagRemoving = ref(false)
  const router = useRouter()
  const { Toast } = useToast()


  const artworkSorters = computed({
    set (value) {
      tagRef.value.artworkIds = [...value]
    },
    get () {
      if (tagRef.value) {
        return tagRef.value.artworkIds
      }
      
      return []
    }
  })


  watch(tagRef, () => {
    if (tagRef.value.title) {
      title.value = tagRef.value.title      
    }
  })


  const updateTitle = async function () {
    try {
      updating.value = true
      await updateSelfTag(tagRef.value._id, {
        title: title.value
      })
      await getTag()
      showEditing.value = false
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      updating.value = false
    }
  }


  const removeTag = async function () {
    try {
      tagRemoving.value = true
      await removeSelfTag(tagRef.value._id)
      router.push({
        path: '/i/publisher/tags',
        replace: true
      })
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      tagRemoving.value = false
    }
  }


  const updateTag = async function (artworkIds) {
    try {
      updating.value = true
      await updateSelfTag(tagRef.value._id, {
        artworkIds: Array.from(new Set([...artworkIds, ...tagRef.value.artworkIds]))
      })
      await getTag()
      showAdder.value = false
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      updating.value = false
    }
  }

  const removeArtworkId = async function (artworkId) {
    try {
      removing.value = true
      const artworkIds = [
        ...tagRef.value.artworkIds
          .filter(iterArtworkId => {
            return iterArtworkId !== artworkId
          })
      ]

      await updateSelfTag(tagRef.value._id, { artworkIds })
      await getTag()
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      removing.value = false
    }
  }


  useSortable(artworksRef, artworkSorters, {
    onUpdate (e) {
      moveArrayElement(artworkSorters.value, e.oldIndex, e.newIndex)
      nextTick(() => {
        updateTag(artworkSorters.value)
      })
    }
  })


  return {
    removeArtworkId,
    updateTag,
    showAdder,
    updating,
    artworksRef,
    removing,
    title,
    showEditing,
    tagRemoving,
    updateTitle,
    removeTag,
  }
}