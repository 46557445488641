//@ts-check
import { APP_URL } from "@/config"
import { limit, useChannelStore } from "@/pinia/channel"
import { eventEmitter, seowords } from "@/utils"
import { events } from "@/utils/events"
import { computed, nextTick, onActivated, onMounted, ref, watch } from "vue"
import { useI18n } from 'vue-i18n'
import { useRoute } from "vue-router"
import { useToast } from "./toast"
import { sanitize } from "dompurify"
import { until, whenever } from "@vueuse/core"
import { useHead } from '@unhead/vue'

// must pass props because props is reactive, props must contains channelId or channel obj
export const useChannelPresent = function (props, options = {
  immediateLoadPosts: true
}) {
  const channelStore = useChannelStore()
  const paramsChannelId = useRoute().params.channelId
  const hotHasmore = ref(true)
  const hotLoading = ref(false)
  const bestHasmore = ref(true)
  const bestLoading = ref(false)
  const latestHasmore = ref(true)
  const latestLoading = ref(false)
  const algoLoading = ref(false)
  const initializing = ref(false)
  const { t } = useI18n()
  const { Toast } = useToast()
  const placeholderText = ref(t('loading'))
  const selectedTabIndex = ref(1)


  const channelId = computed(() => {
    if (paramsChannelId) {
      return paramsChannelId
    }

    return props.channelId
  })

  const channel = computed(() => {
    return channelStore.channels[channelId.value]
  })

  const channelMetaInfo = computed(() => {
    const info = {
      meta: []
    }
    let content = ''

    if (!channel.value) {
      info.title = sanitize(`${t('channels')} | Perohub`)
      info.meta.push({ name: 'keywords', content: seowords })
    } else {
      info.title = `${channel.value.title} | Perohub`
      content = `${sanitize(channel.value.title)} ${sanitize(channel.value.description)}`
      info.meta.push({
        name: 'keywords',
        content: `${sanitize(channel.value.title)},${seowords}`
      }, {
        name: 'twitter:image',
        content: channel.value.coverUrl,
      }, {
        name: 'og:image',
        content: channel.value.coverUrl,
      }, {
        name: 'og:url',
        content: `${APP_URL}/channels/${channel.value._id}`
      })
    }

    info.meta.push({ name: 'description', content: sanitize(content) })
    info.meta.push({
      name: 'twitter:card',
      content: 'summary',
    }, {
      name: 'twitter:site',
      content: '@perohubcom'
    }, {
      name: 'twitter:title',
      content: sanitize(info.title),
    }, {
      name: 'twitter:description',
      content,
    }, {
      name: 'og:title',
      content: sanitize(info.title)
    })

    return info
  })

  useHead(channelMetaInfo)

  watch(channelId, () => {
    selectedTabIndex.value = 1
  })

  whenever(() => channel.value && channel.value._id, () => {
    if (channel.value.useAlgoTimeline) {
      selectedTabIndex.value = 1
    } else {
      selectedTabIndex.value = 0
    }
  })

  watch(selectedTabIndex, () => {
    nextTick(() => {
      if (selectedTabIndex.value === 0) {
        eventEmitter.emit(events.ChannelAlgoVisible, channelId.value)
      } else if (selectedTabIndex.value === 1) {
        eventEmitter.emit(events.ChannelLatestVisible, channelId.value)
      } else if (selectedTabIndex.value === 2) {
        eventEmitter.emit(events.ChannelHotVisible, channelId.value)
      } else if (selectedTabIndex.value === 3) {
        eventEmitter.emit(events.ChannelBestVisible, channelId.value)
      }
    })
  }, { immediate: true })

  const getHotPosts = async function () {
    try {
      hotLoading.value = true
      const beforeCount = channel.value.hotPosts.length
      await channelStore.fetchChannelHotPosts(channelId.value)
      const afterCount = channel.value.hotPosts.length
      if (beforeCount === afterCount) {
        hotHasmore.value = false
      } else {
        hotHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      hotLoading.value = false
    }
  }


  const getBestPosts = async function () {
    try {
      bestLoading.value = true
      const beforeCount = channel.value.bestPosts.length
      await channelStore.fetchChannelBestPosts(channelId.value)
      const afterCount = channel.value.bestPosts.length
      if (beforeCount === afterCount) {
        bestHasmore.value = false
      } else {
        bestHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      bestLoading.value = false
    }
  }


  const getLatestPosts = async function () {
    try {
      latestLoading.value = true
      await until(() => channel.value).toMatch(c => c !== undefined)
      const beforeCount = channel.value.latestPosts.length
      await channelStore.fetchChannelLatestPosts(channelId.value)
      const afterCount = channel.value.latestPosts.length
      if (beforeCount === afterCount) {
        latestHasmore.value = false
        if (afterCount === 0) {
          placeholderText.value = t('channelNoPosts')
        }
      } else {
        latestHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      latestLoading.value = false
    }
  }

  const getAlgoPosts = async function () {
    try {
      algoLoading.value = true
      await channelStore.fetchChannelAlgoPosts(channelId.value)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      algoLoading.value = false
    }
  }

  const init = async function () {
    initializing.value = true
    await channelStore.fetchChannel({
      channelId: channelId.value,
      immediateLoadPosts: options.immediateLoadPosts
    })
    initializing.value = false
  }

  if (!options.immediateLoadPosts) {
    eventEmitter.addListener(events.ChannelAlgoVisible, async (_channelId) => {
      if (_channelId === channelId.value) {
        await until(channel).toMatch(c => c !== undefined)
        if (channel.value.algoPosts.length < limit) {
          getAlgoPosts()
        }
      }
    })

    eventEmitter.addListener(events.ChannelBestVisible, async (_channelId) => {
      if (_channelId === channelId.value) {
        await until(channel).toMatch(c => c !== undefined)
        if (channel.value.bestPosts.length < limit) {
          getBestPosts()
        }
      }
    })

    eventEmitter.addListener(events.ChannelLatestVisible, async (_channelId) => {
      if (_channelId === channelId.value) {
        await until(channel).toMatch(c => c !== undefined)
        if (channel.value.latestPosts.length < limit) {
          getLatestPosts()
        }
      }
    })

    eventEmitter.addListener(events.ChannelHotVisible, async (_channelId) => {
      if (_channelId === channelId.value) {
        await until(channel).toMatch(c => c !== undefined)
        if (channel.value.hotPosts.length < limit) {
          getHotPosts()
        }
      }
    })
  }

  onMounted(() => {
    init()

    eventEmitter.on(events.LoginCompleted, () => {
      init()
    })
    eventEmitter.on(events.LogoutCompleted, () => {
      init()
    })
  })


  onActivated(() => {
    channelStore.$patch({ sourceChannelId: null })
  })


  return {
    channel,
    hotHasmore,
    hotLoading,
    bestHasmore,
    bestLoading,
    latestHasmore,
    latestLoading,
    algoLoading,
    getHotPosts,
    getBestPosts,
    getAlgoPosts,
    getLatestPosts,
    channelMetaInfo,
    placeholderText,
    selectedTabIndex,
    initializing,
  }
}