//@ts-check
import { computed } from 'vue'

export const useUserPageLayout = ({
  userRef,
  issuesRef,
  asksRef,
  userCirclesRef,
}) => {
  const showIssuesTab = computed(() => {
    return userRef.value.authed && (userRef.value.issue.available || issuesRef.value.length > 0)
  })

  const showAsksTab = computed(() => {
    return userRef.value.authed && (userRef.value.ask.available || asksRef.value.length > 0)
  })

  const showCirclesTab = computed(() => {
    return userCirclesRef.value.length > 0
  })

  const userPageTabCount = computed(() => {
    let count = 2
    if (showCirclesTab.value) {
      count += 1
    }
    if (showIssuesTab.value) {
      count += 1
    }
    if (showAsksTab.value) {
      count += 1
    }
    return count
  })

  const issuesTabIndex = computed(() => {
    let index = 2
    if (showCirclesTab.value) {
      index += 1
    }
    return index
  })

  const asksTabIndex = computed(() => {
    let index = 2
    if (showCirclesTab.value) {
      index += 1
    }
    if (showIssuesTab.value) {
      index += 1
    }
    return index
  })

  return {
    showIssuesTab,
    showAsksTab,
    showCirclesTab,
    userPageTabCount,
    issuesTabIndex,
    asksTabIndex
  }
}
