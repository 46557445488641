<template>
  <div
    v-if="gridMedias.length > index"
    class="item-picture-pc">
    <transition-image
      :src="imageURL"
      :styles="{
        width: '100%',
        height: '100%',
        aspectRatio: 1 / 1,
        objectFit: 'cover',
      }">
    </transition-image>
    <div
      v-if="isVideo"
      class="item-picture-cover item-picture-cover-video">
      <img src="@/assets/video_tag.png">
    </div>
    <tag
      v-if="isVideo && (media.canBrowse || post.prices['USD'] === 0)"
      style="position: absolute; top: 0.2rem; left: 0.2rem; font-size: 0.7rem; padding: 0.0rem 0.4rem;"
      value="Free" />
    <div
      v-if="showIndicator"
      class="item-picture-cover item-picture-cover-last">
      <div v-if="imageCount > 0">
        <img src="@/assets/ImageNumber.png">
        <span>{{ imageCount }}</span>
      </div>

      <div v-if="videoCount > 0">
        <img src="@/assets/VideoNumber.png"> 
        <span>{{ videoCount }}</span>
      </div>
    </div>
  </div>
</template>


<script>
//@ts-check
import { useResponsive } from '@/composables/responsive'
import TransitionImage from '@/components/TransitionImage.vue'
import { MediaType } from '@/utils/defines'
import { useArtworkMediasCount } from '@/composables/artwork_medias_count'
import Tag from 'primevue/tag'

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    gridMedias: {
      type: Array,
      required: true
    },
    post: {
      type: Object,
      required: true,
    },
    showIndicator: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    return {
      ...useArtworkMediasCount(props.post),
      ...useResponsive()
    }
  },
  computed: {
    imageURL () { 
      if (this.media.illegalUrl) {
        return this.media.illegalUrl
      }

      if (this.media.thum) {
        return this.media.thum
      }
      return ''
    },
    media () {
      return this.gridMedias[this.index]
    },
    isVideo () {
      return this.media.type === MediaType.VIDEO
    },
    isHigher () {
      return this.gridMedias.length < 4
    }
  },
  components: {
    TransitionImage,
    Tag,
  }
}
</script>

<style lang="less" scoped>
.item-picture-pc {
  max-width: 100%;
  flex: 1;
  position: relative;
  margin-bottom: 1%;

  &:nth-child(even) {
    margin-left: 1%;
    margin-right: 1%;
  }
}

.item-picture-cover {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.33);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
}

.item-picture-cover-last {
  color: #ffffff;
  font-size: 12px;
  align-items: flex-end;
  justify-content: flex-start;

  div {
    height: 16px;
    line-height: 16px;
    display: inline-block;

    &:nth-child(2) {
      margin-left: 6px;
    }

    img {
      width: 15px;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      height: 15px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}

.item-picture-cover-video img {
  width: 3em;
  height: 3em;
}
</style>
