//@ts-check
import { ref } from "vue"
import { useCurrentUser } from "./user_data"
import { useCurrentCurrency } from "./currency"
import { whenever } from "@vueuse/core"

export const useUserIssueEdit = function () {
  const { currentUser, authenticated } = useCurrentUser()
  const { currency } = useCurrentCurrency()
  const availableForIssue = ref(false)
  const minIssuePrices = ref({
    price: 0,
    defaultCurrency: 'JPY',
    prices: {
      USD: 0,
      CNY: 0,
      JPY: 0,
      EUR: 0,
    }
  })
  const issueTemplate = ref('what do you want me to do?\n')

  whenever(authenticated, async () => {
    if (currentUser.value.issue) {
      for (const currency of Object.keys(currentUser.value.issue.minPrices)) {
        if (currency !== 'JPY') {
          currentUser.value.issue.minPrices[currency] = Number((currentUser.value.issue.minPrices[currency] / 100).toFixed(2))
        }
      }
      minIssuePrices.value.defaultCurrency = currency.value
      minIssuePrices.value.prices = currentUser.value.issue.minPrices
      minIssuePrices.value.price = minIssuePrices.value.prices[minIssuePrices.value.defaultCurrency]
      issueTemplate.value = currentUser.value.issue.template
      availableForIssue.value = currentUser.value.issue.available && currentUser.value.authed
    }
  }, { immediate: true })

  return {
    availableForIssue,
    minIssuePrices,
    issueTemplate,
  }
}