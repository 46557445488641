//@ts-check
import { API_URL, cdn, clientId, streamingUrlRTC } from '@/config'
import axios from 'axios'
import localforage from 'localforage'
import { useApplication } from '@/pinia/application'
import { isOnMobile } from '@/utils'
import { ACCESSTOKEN_HEADER_KEY, ACCESSTOKEN_KEY } from '@/pinia/authenticator'


export const api = axios.create({
  baseURL: API_URL
})

export const rtcapi = axios.create({
  baseURL: streamingUrlRTC
})

export const mediaApi = axios.create({
  baseURL: cdn.global
})

const tokenSetter = async (config) => {
  const accessToken = await localforage.getItem(ACCESSTOKEN_KEY)

  if (!config.headers[ACCESSTOKEN_HEADER_KEY] && accessToken) {
    config.headers[ACCESSTOKEN_HEADER_KEY] = accessToken
  } else if (config.headers[ACCESSTOKEN_HEADER_KEY] && !accessToken) {
    config.headers[ACCESSTOKEN_HEADER_KEY] = undefined
  }

  config.headers['platform'] = 'web'
  config.headers['client-id'] = isOnMobile() ? clientId.mobile : clientId.desktop

  return config
}

const errorHandler = async function (error) {
  if (error.response && error.response.data.code === 119) {
    await localforage.removeItem(ACCESSTOKEN_KEY)
    const application = useApplication()
    application.showLogin()
  }

  if (error.response && error.response.data.message) {
    throw new Error(error.response.data.message)
  }

  throw error
}


api.interceptors.request.use(tokenSetter)
api.interceptors.response.use((response) => {
  return response
}, errorHandler)


rtcapi.interceptors.request.use(tokenSetter)
rtcapi.interceptors.response.use((response) => {
  return response
}, errorHandler)

mediaApi.interceptors.request.use(async config => {
  const accessToken = await localforage.getItem(ACCESSTOKEN_KEY)
  config.params = {
    ...config.params,
    token: accessToken,
  }
  return config
})
mediaApi.interceptors.response.use((response) => {
  return response
}, errorHandler)