<template>
  <div class="notifications-container">
    <div class="actions">
      <pr-button
        size="small"
        :loading="notificationStore.clearing"
        @click="notificationStore.clearAll">
        <i class="pi pi-trash"></i>
      </pr-button>
    </div>

    <notification-cell
      v-for="notification in notificationStore.notifications"
      :key="notification._id"
      :notification="notification"
      class="touchable hoverable"></notification-cell>

    <load-more-button
      :busy="notificationStore.loading"
      :has-more="notificationStore.hasMore"
      @trigger="notificationStore.fetchMore">
      {{ $t('loadMore') }}
    </load-more-button>
  </div>
</template>

<script>
//@ts-check
import NotificationCell from '@/components/NotificationCell.vue'
import { onMounted, ref } from 'vue'
import { useResponsive } from '@/composables/responsive'
import { useNotificationStore } from '@/pinia/notification'
import PrButton from '@/components/PRButton.vue'
import { useHead } from '@unhead/vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'


export default {
  setup () {
    const selected = ref(0)
    const notificationStore = useNotificationStore()

    useHead({
      title: 'Notifications | Perohub',
    })

    onMounted(() => {
      notificationStore.fetch()
    })

    return {
      selected,
      ...useResponsive(),
      notificationStore,
    }
  },
  components: {
    NotificationCell,
    PrButton,
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>
.notifications-container {
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding: 1rem;
  }
}
</style>