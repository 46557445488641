//@ts-check
import { ref } from "vue"
import { useCurrentUser } from "./user_data"
import { useCurrentCurrency } from "./currency"
import { whenever } from "@vueuse/core"

export const useUserAskEdit = function () {
  const { currentUser, authenticated } = useCurrentUser()
  const { currency } = useCurrentCurrency()
  const availableForAsk = ref(false)
  const askPrices = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      USD: 0,
      CNY: 0,
      JPY: 0,
      EUR: 0,
    }
  })

  whenever(authenticated, async () => {
    if (currentUser.value.ask) {
      for (const currency of Object.keys(currentUser.value.issue.minPrices)) {
        if (currency !== 'JPY') {
          currentUser.value.issue.minPrices[currency] = Number((currentUser.value.issue.minPrices[currency] / 100).toFixed(2))
        }
      }
      askPrices.value.price = currentUser.value.ask.prices[currency.value]
      askPrices.value.defaultCurrency = currency.value
      askPrices.value.prices = currentUser.value.ask.prices
      availableForAsk.value = currentUser.value.ask.available && currentUser.value.authed
    }
  }, { immediate: true })

  return {
    availableForAsk,
    askPrices
  }
}