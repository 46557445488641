//@ts-check
import { useArtworkStore } from "@/pinia/artwork"
import artworkapi, { getUserPostsTill, getUserPromotedPosts } from "@/api/post"
import { getUserCircles } from "@/api/circle"
import { computed, onActivated, onMounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useI18n } from 'vue-i18n'
import { eventEmitter, seowords } from "@/utils"
import { APP_URL } from "@/config"
import { events } from "@/utils/events"
import { useAuthenticator } from "@/pinia/authenticator"
import { useUserStore } from "@/pinia/user"
import { useToast } from "./toast"
import mixpanel from "mixpanel-browser"
import { useChannelStore } from "@/pinia/channel"
import { sanitize } from "dompurify"
import { useR18CoverStore } from "@/pinia/r18cover"
import { useHead } from '@unhead/vue'

const limit = 10

export const useUserPresent = function () {
  const route = useRoute()
  const userId = ref(route.params.userId ? String(route.params.userId) : null)
  const username = route.params.username
  const artworkStore = useArtworkStore()
  const hotPage = ref(1)
  const hotLoading = ref(false)
  const hotHasmore = ref(true)
  const latestPage = ref(1)
  const latestLoading = ref(false)
  const latestHasmore = ref(true)
  const { t } = useI18n()
  const authenticator = useAuthenticator()
  const userStore = useUserStore()
  const channelStore = useChannelStore()
  const r18coverStore = useR18CoverStore()
  const circleLoading = ref(false)
  const circles = ref([])

  const { Toast } = useToast()

  let timestamp = Date.now()


  const user = computed(() => {
    if (!userId.value) {
      return null
    }

    return userStore.users[userId.value]
  })


  const hotPosts = computed(() => {
    if (!userId.value) {
      return []
    }

    if (!artworkStore.userArtworks[userId.value]) {
      return []
    }

    return artworkStore.userArtworks[userId.value].hotPostIds.map(artworkId => artworkStore.artworks[artworkId])
  })


  const latestPosts = computed(() => {
    if (!userId.value) {
      return []
    }

    if (!artworkStore.userArtworks[userId.value]) {
      return []
    }

    return artworkStore.userArtworks[userId.value].latestPostIds.map(artworkId => artworkStore.artworks[artworkId])
  })


  const userMetaInfo = computed(() => {
    const info = {
      meta: []
    }
    let content = ''

    if (!user.value) {
      info.title = `${t('users')} | Perohub`
    } else {
      info.title = `${sanitize(user.value.nickname)} at Perohub`
      content = `${sanitize(user.value.nickname)} ${sanitize(user.value.motto)}`
      info.meta.push({
        name: 'keywords',
        content: `${sanitize(user.value.nickname)},${seowords}`
      }, {
        name: 'twitter:description',
        content: sanitize(user.value.motto),
      }, {
        name: 'og:url',
        content: `${APP_URL}/users/${user.value._id}`
      }, {
        name: 'twitter:image',
        content: user.value.avatarURL,
      }, {
        name: 'og:image',
        content: user.value.avatarURL
      })
    }

    info.meta.push({ name: 'description', content: sanitize(content) })
    info.meta.push({
      name: 'twitter:card',
      content: 'summary',
    }, {
      name: 'twitter:site',
      content: '@perohubcom'
    }, {
      name: 'twitter:title',
      content: sanitize(info.title),
    }, {
      name: 'og:title',
      content: sanitize(info.title),
    })

    return info
  })

  useHead(userMetaInfo)


  watch(userId, () => {
    if (hotPosts.value.length === 0 && userId.value) {
      getHotPosts()
    }

    if (latestPosts.value.length === 0 && userId.value) {
      getLatestPosts()
    }
  })


  watch(user, () => {
    if (user.value) {
      mixpanel.track('View User', {
        userId: user.value._id,
        nickname: user.value.nickname
      })

      if (user.value.isR18) {
        r18coverStore.showR18Cover()
      }
    }
  })

  const getCircles = async function () {
    try {
      circleLoading.value = true
      circles.value = await getUserCircles(userId.value)
    } catch (err) {
      Toast(err.message)
    } finally {
      circleLoading.value = false
    }
  }

  const getHotPosts = async function () {
    try {
      hotLoading.value = true
      await authenticator.checkAuth()
      const posts = await artworkapi.getUserHotPosts(userId.value, hotPage.value, limit)
      if (posts.length === 0) {
        hotHasmore.value = false
      } else {
        hotPage.value += 1

        posts.forEach(post => {
          artworkStore.cacheArtwork(post)
          artworkStore.pushUserHotArtwork(userId.value, post._id)
        })
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      hotLoading.value = false
    }
  }


  const getLatestPosts = async function () {
    try {
      latestLoading.value = true
      await authenticator.checkAuth()
      let posts = await getUserPostsTill(userId.value, timestamp, limit)
      if (latestPage.value === 1) {
        const topPosts = await getUserPromotedPosts(userId.value)
        posts = [...topPosts, ...posts]
      }
      if (posts.length === 0) {
        latestHasmore.value = false
      } else {
        latestPage.value += 1
        timestamp = posts[posts.length - 1].createdAt

        posts.forEach(post => {
          artworkStore.cacheArtwork(post)
          artworkStore.pushUserLatestArtwork(userId.value, post._id)
        })
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      latestLoading.value = false
    }
  }


  const getUser = async function () {
    try {
      if (!userId.value && username) {
        userId.value = await userStore.getUserByName(username)
      }

      if (!userStore.users[userId.value]) {
        await userStore.getUserById(userId.value)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const reloadPosts = function () {
    hotPage.value = 1
    latestPage.value = 1
    hotHasmore.value = true
    latestHasmore.value = true
    timestamp = Date.now()

    getHotPosts()
    getLatestPosts()
  }


  onMounted(async () => {
    await getUser()

    if (hotPosts.value.length === 0 && userId.value) {
      getHotPosts()
    }

    if (latestPosts.value.length === 0 && userId.value) {
      getLatestPosts()
    }

    getCircles()

    eventEmitter.on(events.LoginCompleted, reloadPosts)
    eventEmitter.on(events.LogoutCompleted, reloadPosts)
  })


  onActivated(() => {
    channelStore.$patch({ sourceChannelId: null })
  })


  return {
    hotPosts,
    latestPosts,
    hotPage,
    hotLoading,
    hotHasmore,
    latestPage,
    latestLoading,
    latestHasmore,
    getHotPosts,
    getLatestPosts,
    user,
    userMetaInfo,
    circleLoading,
    circles,
    userId,
  }
}