//@ts-check
import { api } from "./base"


export const createAsk = async function ({
  question, artistId, peekPrices, anonymous
}) {
  try {
    const res = await api.post('/asks', {
      question,
      artistId,
      peekPrices,
      anonymous
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getAsk = async function (askId) {
  try {
    const res = await api.get(`/asks/${askId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUserAsks = async function (userId, page = 1, limit = 10) {
  try {
    const res = await api.get(`/users/${userId}/asks`, {
      params: {
        page,
        limit
      }
    })
    return res.data.result.asks
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getMyAsks = async function (page = 1, limit = 10) {
  try {
    const res = await api.get(`/asks/my`, {
      params: {
        page,
        limit
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const payAsk = async function (askId, { currency, processor, subProcessor, returnUrl }) {
  try {
    const res = await api.post(`/asks/${askId}/pay`, {
      currency,
      processor,
      subProcessor,
      returnUrl
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateAsk = async function (askId, { answer }) {
  try {
    const res = await api.put(`/asks/${askId}`, {
      answer
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const deleteAsk = async function (askId) {
  try {
    const res = await api.delete(`/asks/${askId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}