<template>
  <div
    v-if="post"
    class="item-pics-container"
    :class="{ gray: post.fake}">
    <div
      v-if="medias.length > 0"
      class="item-pics-row">
      <post-list-item-picture
        v-for="index in [0, 1, 2]"
        :key="index"
        :index="index"
        :grid-medias="medias"
        :post="post"
        @click.stop.prevent="viewFromGrid(index, medias)"></post-list-item-picture>
    </div>

    <div
      v-if="medias.length > 3"
      class="item-pics-row">
      <post-list-item-picture
        v-for="index in [3, 4, 5]"
        :key="index"
        :index="index"
        :grid-medias="medias"
        :post="post"
        @click.stop.prevent="viewFromGrid(index, medias)"></post-list-item-picture>
    </div>

    <div
      v-if="medias.length > 6"
      class="item-pics-row">
      <post-list-item-picture
        v-for="index in [6, 7, 8]"
        :key="index"
        :index="index"
        :grid-medias="medias"
        :post="post"
        :show-indicator="index === 8"
        @click.stop.prevent="viewFromGrid(index, medias)"></post-list-item-picture>
    </div>
  </div>
</template>

<script>
//@ts-check
import PostListItemPicture from '@/components/PostListItemPicture.vue'
import { useArtworkActions } from '@/composables/artwork_action'

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    medias: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    return {
      ...useArtworkActions(props.post._id),
    }
  },
  components: {
    PostListItemPicture,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.item-pics-container {
  width: 100%;

  .item-pics-row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.item-content {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: inline-block;
  margin-bottom: 10px;

  .item-content-text {
    margin-left: 1em;
    margin-right: 1em;
    font-size: 0.88em;
  }
}

</style>
